body {
	color: #343a40;
	// font-display: optional;
	font-family: Roboto, sans-serif;
	font-size: .875rem;
	min-width: 19rem;
	overflow-y: scroll;
	position: relative;
}

.parsley-error .form-label,
.form-error .form-label {
	color: #dc3545;
}

/* ****************** */
/* Loading animations */
/* ****************** */

.fa-spin-cw {
	animation: fa-spin-cw 2s infinite linear;
}

.fa-spin-cww {
	animation: fa-spin-ccw 2s infinite linear;
}

@keyframes fa-spin-cw {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(359deg);
	}
}

@keyframes fa-spin-ccw {
	0% {
		transform: rotate(359deg);
	}
	100% {
		transform: rotate(0);
	}
}

@keyframes load-strip {
	from {
		background-position: -32px 0;
	}
	to {
		background-position: 0 0;
	}
}

.load-strip,
.load-strip::before {
	color: #6464f3;
	background-color: currentcolor;
	height: 3px;
	left: 0;
	position: fixed;
	top: 0;
}

.load-strip {
	animation: load-strip .5s linear infinite;
	background-image: linear-gradient(135deg, rgba(255, 255, 255, .5) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .5) 50%, rgba(255, 255, 255, .5) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
	background-size: 32px 32px;
	content: "";
	opacity: 0;
	transition: opacity .1s, width .3s;
	width: 0;
	z-index: 100001;
}

.load-strip::before {
	content: "";
	opacity: .2;
	width: 100%;
}

.load-strip::after {
	border-color: currentcolor rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) currentcolor;
	border-style: solid;
	border-width: 2px;
	content: "";
	left: 100%;
	position: absolute;
	top: 0;
}

.transition-none {
	transition: none !important;
}

.disabled {
	cursor: not-allowed;
}

.disabled {
	opacity: .65;
	pointer-events: none;
}

.object-fit-cover {
	object-fit: cover;
}

.pointer-none {
	pointer-events: none;
}

.pointer-auto {
	pointer-events: auto;
}

.loading {
	overflow: hidden;
	position: relative;
	cursor: progress;
}

.loading.loading-auto {
	overflow: inherit;
}

.loading-absolute {
	position: absolute;
}

.loading::after,
.loading-transparent::after {
	background: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22m8%201a7%207%200%200%201%207%207v0a7%207%200%200%201-7%207%22%20fill%3D%22none%22%20stroke%3D%22%23868e96%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%221%22%3E%3CanimateTransform%20attributeName%3D%22transform%22%20type%3D%22rotate%22%20from%3D%220%208%208%22%20to%3D%22360%208%208%22%20dur%3D%22500ms%22%20repeatCount%3D%22indefinite%22%3E%3C%2FanimateTransform%3E%3C%2Fpath%3E%3C%2Fsvg%3E") no-repeat center center / contain;
}

.loading::after {
	background-color: rgba(255, 255, 255, .7);
	border-radius: inherit;
	border: 5px solid rgba(0, 0, 0, 0);
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10001;
}

.loading-transparent::after {
	background-color: rgba(255, 255, 255, 0);
}

body.loading::after,
.loading-max::after {
	background-size: 192px 192px;
}

body.loading::after {
	position: fixed;
}

.loading-md {
	min-height: 64px;
	min-width: 64px;
}

body.loading-md::after,
.loading-md::after {
	background-size: 64px 64px;
}

.loading-sm {
	min-height: 32px;
	min-width: 32px;
}

body.loading-sm::after,
.loading-sm::after {
	background-size: 32px 32px;
}

.loading.loading-xs::after {
	background-size: 24px 24px;
}

/* div.loader.visible */
/* div.loader-wrapper.visible */
/* div.loader-animation */

.loader {
	opacity: 0;
	background-color: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -100;
	transition: opacity .3s ease-out, z-index .3s step-end;
}

.loader.visible {
	cursor: progress;
	opacity: .7;
	z-index: 2000;
	transition: opacity .2s ease-in, z-index .2s step-start;
}

.loader .loader-wrapper {
	display: none;
	margin: 40vh auto auto;
	margin: calc(var(--vh, 1vh) * 40) auto auto;
	padding-left: 1em;
	padding-right: 1em;
	transition: visibility .3s step-end;
	visibility: hidden;
	width: 195px;
}

.loader .loader-wrapper.visible {
	display: block;
	visibility: visible;
}

@media screen and (min-width: 52.5em) {
	.loader {
		position: absolute;
	}
	.loader .loader-wrapper {
		margin-top: 20vh;
		margin-top: calc(var(--vh, 1vh) * 20);
	}
}

.loader .loader-animation {
	border-radius: 50%;
	position: fixed;
	display: inline-block;
	vertical-align: middle;
	width: 80px;
	height: 80px;
	margin: 3em;
}

.loader .loader-animation::before,
.loader .loader-animation::after {
	position: absolute;
	content: '';
	border: 4px solid #5858dc;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple .7s cubic-bezier(0, .2, .8, 1) infinite;
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
}
.loader .loader-animation::after {
	animation-delay: -.35s;
}

/* ****************** */

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ripple::before,
.lds-ripple::after {
	position: absolute;
	content: '';
	border: 4px solid #5858dc;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, .2, .8, 1) infinite;
}
.lds-ripple::after {
	animation-delay: -.5s;
}
@keyframes lds-ripple {
	0% {
		top: 36px;
		left: 36px;
		width: 0;
		height: 0;
		opacity: 1;
		border: 4px solid #5858dc;
	}
	100% {
		top: 0px;
		left: 0px;
		width: 72px;
		height: 72px;
		opacity: 0;
		border-color: #5858dc;
	}
}

@keyframes loading {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

.btn-loading::after,
.over-loading::after,
.over-message::after {
	background: rgba(255, 255, 255, .95);
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.over-loading.bg-none::after {
	background: none;
}

.over-message::before {
	border-radius: 4px;
	box-sizing: border-box;
	content: attr(data-message);
	font-size: 12px;
	left: 50%;
	min-height: 28px;
	min-width: 28px;
	padding: 5px 10px;
	position: absolute;
	text-align: center;
	text-decoration: inherit;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: 2;
}

.over-loading .over-error::before,
.over-loading .over-message::before {
	content: "";
}

.btn-loading::before,
.over-loading::before,
.input-loading {
	animation: .6s linear infinite loading;
	border-color: #999 rgba(255, 255, 255, 0) rgba(255, 255, 255, 0);
	border-radius: 60%;
	border-style: solid;
	border-width: 2px;
	content: "";
	display: block;
	height: 24px;
	left: 50%;
	margin: -12px 0 0 -12px;
	position: absolute;
	top: 50%;
	width: 24px;
	z-index: 2;
}

.over-loading::before {
	background: none;
}

.btn-loading::before {
	animation-duration: .5s;
	border-right-color: currentColor;
	border-top-color: currentColor;
	height: 12px;
	margin: -8px 0 0 -8px;
	width: 12px;
}

.btn-loading::after {
	background: inherit;
	border-radius: inherit;
}

.btn-loading.btn-link::after {
	background: #f4f6f8;
}

.btn-loading {
	position: relative;
}

/* ****************** */
/* ** Overlayers ** */
/* ****************** */

.fancybox-container {
	z-index: 13005;
}

.fancybox-is-open .fancybox-bg {
	opacity: .95;
}

.fancybox-show-thumbs .fancybox-inner {
	right: 175px;
}

.fancybox-slide--current::before {
	content: none;
}

.fancybox-slide--current {
	display: flex;
	justify-content: center;
	align-items: center;
}

.fancybox-slide--complete .fancy-wrapper {
	opacity: 1;
	transform: scaleY(1);
}

.fancybox-thumbs {
	width: 175px;
}

.fancybox-thumbs > ul > li {
	height: 85px;
	width: 85px;
}

.fancybox-thumbs > ul > li > img {
	object-fit: cover;
}

.fancy-wrapper {
	border-radius: 3px;
	/* margin: 10px; */
	margin: 0;
	/* max-height: calc(100vh - 20px);
	max-width: calc(100vw - 20px); */
	opacity: 0;
	/* outline: 1px solid rgba(0, 0, 0, 0); */
	overflow: hidden;
	padding: 0;
	transform: scaleY(0);
	transition: opacity .3s, transform .3s;
}

.fancy-wrapper .fancy-content {
	overflow-x: hidden;
	overflow-y: auto;
}

.fancy-wrapper .fancy-title.hide + .fancy-content {
	padding-bottom: 0;
	top: 0;
}

.fancy-wrapper .fancy-title,
.fancy-wrapper .fancy-footer {
	left: 0;
	position: absolute;
	right: 0;
}

.fancy-wrapper .fancy-title {
	color: #495057;
	// font-display: optional;
	font-family: Roboto, sans-serif;
	font-size: 20px;
	line-height: 27px;
	margin: 0;
	overflow: hidden;
	padding: 10px 45px 10px 20px;
	text-overflow: ellipsis;
	top: 0;
	white-space: nowrap;
}

.fancy-title.fancy-title-underline {
	border-bottom: 1px solid #ddd;
	margin-bottom: 10px;
}

.module-admin .fancy-wrapper .fancy-title {
	background: #f8f8f8;
	border-bottom: 1px solid #e8e8e8;
	min-height: 54px;
	padding: 15px 35px 15px 15px;
}

.fancy-wrapper .fancy-footer {
	bottom: 0;
	padding: 10px 20px 20px 20px;
	background: #fff;
}

.fancy-content-footerless {
	max-height: calc(100vh - 68px) !important;
	margin-bottom: 48px !important;
}

.fancy-content-footerless ~ .fancy-footer {
	display: none;
}

.fancy-wrapper .fancybox-close-small {
	right: 0;
	top: 0;
}

.fancy-wrapper .fancybox-close-small::after {
	font-size: 24px;
	height: 30px;
	line-height: 30px;
	right: 10px;
	top: 10px;
	width: 30px;
}

.fancy-wrapper .fancybox-close-small:hover::after {
	background-color: rgba(0, 0, 0, 0);
}

@media (max-width: 767.98px) {
	.fancybox-slide--current {
		display: block !important;
		overflow: hidden;
	}
	.fancy-wrapper.fancy-wrapper {
		margin: 0;
		border-radius: 0;
		height: 100% !important;
		width: 100% !important;
		max-height: none !important;
		min-height: 0 !important;
		max-width: none !important;
		min-width: 0 !important;
		transition: opacity .3s;
	}
}

.fancy-wrapper .fancy-content {
	box-sizing: border-box;
	margin: 0;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 15px;
}

.shm-overlayer .fancy-content {
	min-height: 162px;
	outline: 0;
}

.shm-overlayer .container-center {
	background: radial-gradient(#f1f3f5, #fff);
	min-width: 0;
}

.shm-dialog .fancy-content {
	min-height: 80px;
	max-width: 600px;
}

@media (min-width: 500px) {
	.shm-dialog .fancy-content {
		min-width: 500px;
	}
}

.custom-err-msg .close {
	display: none;
}

.fancy-wrapper .content {
	padding: 0;
}

.fancy-wrapper .column-left {
	width: 100%;
}

.fancy-wrapper .column-left > *:last-child .form-section {
	padding-bottom: 0;
}

.fancy-wrapper .column-section {
	display: block;
	margin: 0;
	padding: 0 !important;
	top: 0;
}

.fancy-wrapper .column-section-actions {
	background: none;
	position: static;
}

.fancy-wrapper .column-side {
	max-width: none;
	width: 100%;
	margin: 15px 0;
	position: static;
}

.fancy-wrapper.fancy-wrapper .column-title {
	padding-top: 0;
}

.fancy-wrapper .column-hint {
	margin: 0;
}

.column-hint a {
	color: #6c757d;
}

.column-hint a:hover {
	color: #666;
}

.fancy-wrapper .column-main {
	width: auto;
}

.fancy-wrapper .column-box {
	border-width: 0;
	border-radius: 0;
	box-shadow: none;
}

.fancy-wrapper .form-section {
	padding: 0;
}

.fancy-wrapper.fancy-wrapper .bottom-bar {
	border: 0;
	height: auto;
	margin: 0;
	padding: 0;
	position: static;
}

.shm-debug-layout {
	position: relative;
	z-index: 1;
}

/* ****************** */
/* ****************** */

.toast-top-left,
.toast-bottom-left {
	left: 15px;
}

.toast-top-right,
.toast-bottom-right {
	right: 15px;
}

.default-toast-container {
	pointer-events: none;
	position: fixed;
	z-index: 999999;
}

.default-toast-container * {
	box-sizing: border-box;
}

.default-toast-container[class*="toast-top"] {
	top: 50px;
}

.top-hint-shown .default-toast-container[class*="toast-top"] {
	top: 80px;
}

.default-toast-container[class*="-bottom-"] {
	bottom: 0;
}

.default-toast-container .toast,
.default-toast-container .toast:hover {
	box-shadow: 0 2px 10px rgba(0, 0, 0, .25);
}

.default-toast-container[class*="-left"],
.default-toast-container[class*="-right"],
.default-toast-container[class*="-center"] {
	max-width: 600px;
	min-width: 400px;
	width: max-content;
}

@media (min-width: 1440px) {
	.default-toast-container[class*="-left"],
	.default-toast-container[class*="-right"],
	.default-toast-container[class*="-center"] {
		max-width: 800px;
		min-width: 540px;
	}
}

.default-toast-container[class*="-center"] {
	left: 50%;
	transform: translateX(-50%);
}

.default-toast-container[class*="-full-width"],
.default-toast-container[class*="-full-width"] .toast {
	width: 100vw;
}

.default-toast-container .toast {
	background: #fff;
	border-radius: 0;
	cursor: default;
	margin: 10px auto;
	max-width: calc(100vw - 30px);
	opacity: 1;
	padding: 15px 30px 15px 15px;
	pointer-events: auto;
	position: relative;
}

@media (min-width: 992px) {
	.default-toast-container[class*="-left"] {
		left: 230px;
	}
	.default-toast-container[class*="-right"] {
		right: 30px;
	}
	.default-toast-container[class*="-center"] {
		left: calc(50% + 100px);
	}
	.default-toast-container[class*="-full-width"] {
		left: 100px;
	}
	.default-toast-container .toast {
		max-width: calc(100vw - 260px);
	}
}

.default-toast-container .toast:hover,
.default-toast-container .toast-close-button:hover {
	cursor: default;
}

.default-toast-container .toast-close-button {
	background: none;
	color: rgba(0, 0, 0, .2);
	font-size: 22px;
	font-weight: normal;
	height: 28px;
	opacity: 1;
	position: absolute;
	right: 0;
	text-shadow: none;
	top: 3px;
	transition: color .2s ease-in-out;
	width: 28px;
}

.default-toast-container .toast-close-button:hover {
	color: rgba(0, 0, 0, .4);
	opacity: 1;
}

.default-toast-container .toast-title,
.default-toast-container .toast-message {
	display: inline;
	line-height: 2;
}

.default-toast-container .toast-title {
	font-size: 14px;
	font-weight: bold;
}

.default-toast-container .toast-message {
	word-wrap: break-word;
}

.default-toast-container .toast-message > .toast-title {
	display: block;
	line-height: 1.25;
}

.default-toast-container .toast-title + .toast-message {
	display: block;
	margin-top: 5px;
}

.default-toast-container .toast,
.default-toast-container .toast a,
.default-toast-container .toast label {
	color: #495057;
	// font-display: optional;
	font-family: Roboto, sans-serif;
}

.default-toast-container .toast::before,
.siren::before {
	align-items: center;
	border-radius: 50%;
	box-sizing: content-box;
	content: "\3f";
	display: inline-flex;
	font-size: 14px;
	height: 16px;
	justify-content: center;
	margin-bottom: 5px;
	margin-right: 7px;
	width: 16px;
}

.default-toast-container .toast::before {
	content: none;
	// font-display: optional;
	font-family: Roboto, sans-serif;
	opacity: .9;
	padding: 2px;
}

.default-toast-container .toast.toast::before {
	border: none;
	color: #fff;
}

.default-toast-container .toast::after {
	content: "";
	height: 3px;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.default-toast-container .toast-success::before,
.default-toast-container .toast-success::after,
.default-toast-container .toast-success .toast-progress {
	background: #40c057;
}

.default-toast-container .toast-success {
	background: #ebf9ee;
}

.default-toast-container .toast-warning::before,
.default-toast-container .toast-warning::after,
.default-toast-container .toast-warning .toast-progress {
	background: #fab005;
}

.default-toast-container .toast-warning {
	background: #fff7e5;
}

.default-toast-container .toast-error::before,
.default-toast-container .toast-error::after,
.default-toast-container .toast-error .toast-progress {
	background: #fa5252;
}

.default-toast-container .toast-error {
	background: #ffeded;
}

.default-toast-container .toast-info::before,
.default-toast-container .toast-info::after,
.default-toast-container .toast-info .toast-progress {
	background: #228be6;
}

.default-toast-container .toast-info {
	background: #e8f3fd;
}

.default-toast-container .toast-progress::before {
	background: inherit;
	content: "";
	height: inherit;
	left: -100vw;
	opacity: .5;
	position: absolute;
	right: 0;
}

.default-toast-container .toast-progress {
	height: 2px;
	left: auto;
	opacity: .75;
	right: 0;
}

.app-toast-container.app-toast-container {
	max-width: 500px;
}

.app-toast-container .toast.toast::before,
.app-toast-container .toast.toast::after {
	content: none;
}

.app-toast-container .toast.toast {
	background: #fff;
	padding: 15px 25px 15px 15px;
}

.app-toast-container .toast .toast-close-button {
	cursor: pointer;
	font-size: 18px;
	font-weight: bold;
	line-height: 1;
}

.app-toast-container .toast .toast-message {
	margin-top: 0;
}

.app-toast-container .toast-content,
.app-toast-container .toast-content .toast-title {
	display: block;
	line-height: 1.25;
}

.app-toast-container .toast-content .toast-title {
	font-size: 14px;
	margin-bottom: 2px;
}

.app-toast-container .toast-content {
	width: 100%;
}

.app-toast-container .toast-image {
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: 3px;
	flex-shrink: 0;
	height: 34px;
	margin-right: 10px;
	width: 34px;
}

.app-toast-container .toast-date {
	color: #6c757d;
	font-size: 12px;
	margin-top: 2px;
}

.app-toast-container .toast-desc-gray {
	background: #f8f9fa;
	border-bottom: 1px solid #e9ecef;
	margin: -15px -25px 10px -15px;
	padding: 15px 25px 10px 15px;
}

.app-toast-container .toast-desc-gray ~ hr {
	border-top-color: #e9ecef;
	margin: 10px -25px 10px -15px;
}

.app-toast-container .toast-desc .customer-acronym {
	color: #fff;
	pointer-events: none;
}

.toast-table tr > * {
	padding: 3px 6px;
}

.toast-table tr > :first-child {
	padding-left: 0;
}

.toast-table tr > :last-child {
	padding-right: 0;
}

.toast-table .form-badge {
	height: auto;
	white-space: normal;
}

.siren::before {
	background: #fff;
	color: rgba(134, 142, 150, 1);
	border: 2px solid currentColor;
	box-shadow: 0 0 0 3px rgba(134, 142, 150, .25);
}

.siren {
	background: #f1f3f5;
	border: 0;
	border-radius: 2px;
	color: #343a40;
	line-height: 22px;
	margin-bottom: 10px;
	padding: 12px 15px;
}

.siren:last-child {
	margin-bottom: 0;
}

.default-toast-container .toast-success::before,
.siren-success::before {
	box-shadow: 0 0 0 3px rgba(55, 178, 77, .25);
	color: rgba(55, 178, 77, 1);
	content: "\2714";
}

.siren-success {
	background: #e9f9ec;
}

.default-toast-container .toast-error::before,
.siren-danger::before {
	box-shadow: 0 0 0 3px rgba(250, 82, 82, .25);
	color: rgba(250, 82, 82, 1);
	content: "\00d7";
	font-weight: bold;
}

.siren-danger {
	background: #fff0f6;
}

.default-toast-container .toast-warning::before,
.siren-warning::before {
	box-shadow: 0 0 0 3px rgba(245, 159, 0, .25);
	color: rgba(245, 159, 0, 1);
	content: "\01c3";
	font-family: sans-serif;
	font-weight: bold;
}

.siren-warning {
	background: #fff4e6;
}

.default-toast-container .toast-info::before,
.siren-info::before {
	box-shadow: 0 0 0 3px rgba(34, 184, 207, .25);
	color: rgba(34, 184, 207, 1);
	content: "\1d48a";
}

.siren-info {
	background: #edffff;
}

/* ****************** */
/* ****************** */

summary {
	list-style: none;
	position: relative;
}

summary::-webkit-details-marker {
	display: none;
}

details > summary > .svg-icon {
	color: #495057;
	height: .75rem;
	opacity: .35;
	position: absolute;
	margin: .75rem;
	right: 0;
	top: 0;
	width: .75rem;
}

details > summary:hover > .svg-icon {
	opacity: .65;
}

details[open] > summary > .svg-icon {
	transform: scaleY(-1);
}

.list-custom,
.has-list-custom ul {
	list-style: none;
	padding-left: 0;
}

.list-custom > li,
.has-list-custom ul > li {
	margin-bottom: .375rem;
}

.list-custom > li::before,
.has-list-custom ul > li::before {
	background: #adb5bd;
	border-radius: 25%;
	content: "";
	display: inline-block;
	height: .375rem;
	margin-right: .375rem;
	position: relative;
	top: -.125rem;
	width: .375rem;
}

/* details > summary + div {
	animation: slideUp .1s;
	overflow: hidden;
} */

/* details[open] > summary + div {
	animation: slideDown .2s;
	overflow: hidden;
} */

/* .modal-custom-details[open] .modal-custom-item {
	animation: slideDown .2s;
} */

[data-count]::before {
	animation: bounceIn .5s ease-out;
	background: #e6007d;
	border-radius: 1rem;
	color: #fff;
	content: attr(data-count);
	font-family: Arial, sans-serif;
	font-size: .75rem;
	font-weight: 600;
	line-height: 1;
	min-width: 1.25rem;
	padding: .25rem;
	position: absolute;
	right: -.25rem;
	text-align: center;
	top: -.25rem;
	white-space: nowrap;
	z-index: 1;
}

[data-count=""]::before {
	content: none;
}

[data-count] {
	position: relative;
}

@keyframes filterSlideDown {
	0% {
		height: 0;
		opacity: 0;
	}
	100% {
		height: 50px;
		opacity: 1;
	}
}

.filterSlideDown {
	animation: filterSlideDown .2s;
}

b,
dt,
strong,
.bold {
	font-weight: 500;
}

:focus {
	outline: 0 !important;
}

.focus-visible:not(.form-control),
.focus-visible.custom-control-input ~ .custom-control-label,
.brand-desc-checkbox:focus ~ .brand-desc-wrap .btn-show-more-desc {
	box-shadow: 0 0 0 2px #e6007d inset !important;
}

/* The input click is buggy inside an anchor, so we handle it from javascript */
a .custom-control-input {
	pointer-events: none;
}

.filter-link {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}

.filter-item .filter-link::before {
	background-color: #6464f3;
	border-radius: 50%;
	content: "";
	height: .5rem;
	left: .5rem;
	margin-top: .125rem;
	opacity: 0;
	position: absolute;
	top: calc(50% - 6px);
	transform: scale(2.5);
	width: .5rem;
	z-index: 99999;
}

.filter-item .filter-link:focus::before,
.filter-item .filter-link:active::before {
	animation: bubble-blink .3s .1s both ease-out;
}

@keyframes bubble-blink {
	from {
		transform: scale(0);
		opacity: 0;
	}
	50% {
		transform: scale(1.5);
		opacity: .25;
	}
	to {
		transform: scale(4);
		opacity: 0;
	}
}

.form-control {
	border-color: #ced4da;
	font-size: .875rem;
}

.form-control::placeholder {
	color: #adb5bd;
}

.form-control:focus,
.form-control:invalid,
.focus-visible[tabindex="-1"],
.index-cat-link.focus-visible,
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none !important;
}

.form-control:focus {
	border-color: #aaaaed;
}

.custom-control-label::before {
	top: .1875rem;
}

.custom-control-input.is-invalid ~ .custom-control-label {
	color: inherit;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
	border-color: #adb5bd;
}

.custom-switch .custom-control-label::before {
	background: #6c757d;
	border: 0;
	top: .125rem;
}

.custom-switch .custom-control-input:active ~ .custom-control-label::before {
	background: #aaaaed;
}

.custom-switch .custom-control-input:checked:active ~ .custom-control-label::before {
	background: #adb5bd;
}

.custom-switch .custom-control-label::after {
	background: #fff;
	top: .25rem;
}

.custom-switch .custom-control-label {
	cursor: pointer;
	font-size: .8125rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: #6464f3;
	background-color: #6464f3;
}

.btn-input-wrap {
	position: relative;
}

.btn.btn-input {
	padding: .5rem .75rem;
	position: absolute;
	min-height: 100%;
	opacity: .25;
	top: 0;
	transition: opacity .15s ease-in-out;
	z-index: 1;
}

.btn.btn-input:hover {
	opacity: .5;
}

.btn-input-left > .form-control {
	padding-left: 2.5rem;
}

.btn-input-right > .form-control {
	padding-right: 2.5rem;
}

.btn-input-left > .btn-input {
	left: 0;
}

.btn-input-right > .btn-input {
	right: 0;
}

.btn-input > .btn-input-icon {
	height: 1rem;
	width: 1rem;
}

.btn-input > .btn-input-icon + .btn-input-icon {
	display: none;
}

.form-control.form-control-lg {
	background-image: none;
	border-radius: .25rem;
	font-size: 1rem;
	min-height: calc(2.5rem + 2px);
}

.form-control-lg ~ .btn-input {
	padding-left: 1rem;
	padding-right: 1rem;
}

.btn-input-left > .form-control-lg {
	padding-left: 3.25rem;
}

.btn-input-right > .form-control-lg {
	padding-right: 3.25rem;
}

.hint {
	color: #6c757d;
	font-size: .8125rem;
}

.form-control + .hint {
	margin-top: .25rem;
}

.serif {
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
}

.wrap {
	background: #f1f3f5;
	display: flex;
	flex-direction: column;
}

.main {
	width: 100%;
}

.container,
.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.container {
	max-width: 80rem;
	width: 100%;
}

[class^="ajax-fragment-"] {
	max-width: 100%;
}

.svg-icon {
	height: 1.5rem;
	transform: translate(0, 0);
	vertical-align: middle;
	width: 1.5rem;
}

.svg-icon-xxs {
	height: .5rem;
	width: .5rem;
}

.svg-icon-xs {
	height: .75rem;
	width: .75rem;
}

.svg-icon-sm {
	height: 1rem;
	width: 1rem;
}

.svg-icon-md {
	height: 2rem;
	width: 2rem;
}

.svg-icon-lg {
	height: 2.5rem;
	width: 2.5rem;
}

.svg-icon-xl {
	height: 3rem;
	width: 3rem;
}

.svg-icon-xxl {
	height: 4rem;
	width: 4rem;
}

.svg-icon-inherit {
	height: 1em;
	width: 1em;
}

.svg-icon-pos-left,
.svg-icon-pos-right {
	margin: 0;
	position: absolute;
}

.svg-icon-pos-left {
	left: 1rem;
}

.svg-icon-pos-right {
	right: 1rem;
}

.svg-icon > use[href="#spinner"],
.svg-icon > use[xlink\:href="#spinner"] {
	animation: .6s linear infinite fa-spin-cw;
	transform-origin: 50% 50%;
}

.wrap {
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
}

.header-top {
	background: #5858dc;
	color: #fff;
	font-size: .9375rem;
}

.header-container {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
}

.header-top-container {
	padding: .75rem;
}

.header-bottom {
	background: #fff;
	box-shadow: 0 0 .75rem rgba(75, 75, 189, .15);
	display: none;
	font-size: .875rem;
}

.header-btn {
	align-items: center;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-color: #5151ca;
	border: 0;
	border-radius: 2em;
	color: inherit;
	display: inline-flex;
	padding: .5rem;
	transition: background-color .15s ease-in-out;
	white-space: nowrap;
}

.header-btn:hover {
	background-color: #4b4bbd;
	color: #fff;
}

.header-btn-heart {
	margin-right: .75rem;
}

.header-section-logo,
.header-section-account {
	display: flex;
	flex-wrap: nowrap;
}

.header-logo,
.header-logo:hover {
	color: inherit;
}

.header-logo-img {
	font-size: 1.5rem;
	height: 2.5rem;
	width: 6.5rem;
}

.header-btn-menu {
	margin-right: .75rem;
	order: -1;
}

.header-section-search {
	flex-basis: 100%;
	flex-grow: 1;
	order: 1;
	margin-top: .75rem;
	position: relative;
}

.header-section-search-open {
	z-index: 10000;
}

.header-input-search {
	border-radius: 1.25rem;
	font-size: inherit;
	height: 2.5rem;
	padding: .5rem 4rem .5rem 1rem;
}

.header-input-search,
.header-input-search:focus {
	border: thin solid transparent;
}

.header-btn.header-btn-search,
.header-btn.header-btn-clear {
	background: none;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.header-btn-search {
	color: #5858dc;
}

.header-btn-search:hover {
	color: #4b4bbd;
}

.header-btn-clear,
.header-btn-clear:hover {
	color: #6c757d;
}

.header-btn-clear {
	border-radius: 0;
	padding: .75rem .1875rem;
	transform: translateX(calc(-100% - 1rem));
	transition: opacity .15s ease-in-out, visibility .15s ease-in-out;
}

.header-input-search:not(:focus):placeholder-shown ~ .header-btn-clear {
	opacity: 0;
	visibility: hidden;
}

.header-input-search.ac-loading ~ .header-btn-clear::before {
	animation: fadeIn .3s .3s both, fa-spin-ccw .5s infinite linear;
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23adb5bd' d='M12.596 12.596a6.5 6.5 0 110-9.192'/%3e%3c/svg%3e") no-repeat 50% 50% / 1rem 1rem;
	border-radius: 50%;
	bottom: .5rem;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: .5rem;
	z-index: 1;
}

.header-section-account {
	margin-left: auto;
}

.shm-card {
	border: 0;
	border-radius: 0;
	box-shadow: 0 .5rem .75rem -.5rem rgba(73, 80, 87, .15);
}

.shm-card + .shm-card {
	border-top: 1px solid #dee2e6;
}

.shm-card-body {
	padding: 1rem .75rem;
}

.h1,
.h2 {
	font-weight: 500;
	margin-bottom: 1rem;
}

.h1 {
	font-size: 1.25rem;
}

.h2 {
	font-size: 1.1875rem;
}

.h3 {
	font-size: 1rem;
}

.h1-overlayer,
.h1-overlayer:hover {
	background: rgba(0, 0, 0, .5);
	color: #ffffff;
	font-size: 1.5rem;
	margin: 1rem;
	padding: 1rem;
	position: absolute;
	text-decoration: none;
}

.header-count {
	color: #6c757d;
	font-size: .875rem;
	font-weight: 400;
}

.modal-menu {
	padding-right: 0 !important;
}

.cat-menu-list {
	background: #fff;
	bottom: 0;
	font-size: .875rem;
	left: 0;
	list-style: none;
	margin: 0;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	position: absolute;
	right: 0;
	scrollbar-color: rgba(0, 0, 0, .25) rgba(0, 0, 0, 0);
	scrollbar-width: thin;
	top: 0;
	transition: transform .15s ease-in-out;
}

.cat-menu-list::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 6px;
}

.cat-menu-list::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, .25);
}

.cat-menu-list-secondary:not(.active) {
	transform: translateX(100%);
}

.cat-menu-list-primary:not(.active),
.cat-menu-list.cat-menu-slide-left {
	transform: translateX(-100%);
}

.cat-menu-item {
	border-bottom: thin solid #e9ecef;
	transition: opacity .2s ease-in-out;
}

.cat-menu-item:last-child {
	margin-bottom: 1.5rem;
}

.cat-menu-link.cat-menu-link {
	align-items: center;
	color: inherit;
	cursor: pointer;
	display: flex;
	margin: 0;
	padding: .75rem 1rem;
	text-decoration: none;
}

.cat-menu-item:hover a.cat-menu-link {
	background-color: #f1f3f5;
}

.cat-menu-list-primary a.cat-menu-link {
	font-size: .9375rem;
}

.cat-menu-item-header .cat-menu-link[href^="javascript"],
.cat-menu-item-header .cat-menu-link[href^="#"],
.cat-menu-item-header span.cat-menu-link {
	background: none;
	cursor: default;
	pointer-events: none;
}

.cat-menu-item-header a.cat-menu-link {
	background: #ebebff;
}

.cat-menu-item-parent .cat-menu-link {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23495057' opacity='.5' d='M5.5 13.5L11 8 5.5 2.5'/%3e%3c/svg%3e") no-repeat right .75rem center / 1rem 1rem;
	padding-right: 2rem;
}

.cat-menu-item-parent:hover .cat-menu-link {
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23495057' opacity='.75' d='M5.5 13.5L11 8 5.5 2.5'/%3e%3c/svg%3e");
}

.cat-menu-item.cat-menu-item-back {
	border-bottom: thin solid #dee2e6;
	position: sticky;
	top: 0;
}

.cat-menu-item.cat-menu-item-back .cat-menu-link {
	background: #f1f3f5 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%236c757d' d='M11.5 13.5L6 8l5.5-5.5'/%3e%3c/svg%3e") no-repeat left .75rem center / 1rem 1rem;
	color: #6c757d;
	padding-left: 2rem;
}

.cat-menu-item-header {
	font-weight: 500;
	text-transform: uppercase;
}

.cat-menu-item-more .cat-menu-link {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23868e96' d='M3.5 5.5L8 10l4.5-4.5'/%3e%3c/svg%3e") no-repeat left .75rem center / 1rem 1rem;
	color: #6c757d;
	font-size: .8125rem;
	line-height: 1.25rem;
	padding-left: 2rem;
}

.cat-menu-item-hidden {
	opacity: 0;
	height: 0;
	overflow: hidden;
}

.cat-menu-icon {
	height: 1.25rem;
	margin-right: .625rem;
	opacity: calc(200% / 3);
	width: 1.25rem;
}

.cat-desc {
	background: #f8f9fa;
	color: #495057;
}

.cat-desc-inner > p:last-child {
	margin-bottom: 0;
}

.cat-desc-collapsed::before {
	background: linear-gradient(rgba(248, 249, 250, 0), #f8f9fa);
	bottom: 0;
	content: "";
	height: 4rem;
	left: 0;
	position: absolute;
	right: 0;
}

.cat-desc-collapsed {
	height: 15rem;
	overflow-y: hidden;
	position: relative;
}

.cat-desc-toggle {
	display: inline-block;
	margin-top: .5rem;
}

.cat-desc h1,
.cat-desc h2,
.cat-desc h3 {
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
	font-size: 1.125rem;
	margin: 1rem 0;
}

.cat-desc h2:first-child,
.cat-desc h3:first-child {
	margin-top: 0;
}

.cat-desc h2:not(:first-child),
.cat-desc h3:not(:first-child) {
	margin-top: 1.5rem;
}

.cat-desc h3 {
	font-size: 1rem;
	margin-bottom: .75rem;
}

.about-desc h1 {
	margin: 0 0 .75rem;
}

.cat-desc a:not([class]) {
	border-bottom: thin dotted;
	color: inherit;
	text-decoration: none;
}

.cat-desc a:not([class]):hover {
	border-bottom: thin solid #6c757d;
	color: inherit;
}

.cat-desc .strip-wrap::before,
.cat-desc .strip-wrap::after {
	content: none;
}

.cat-desc .strip-wrap {
	margin-bottom: 0;
}

.cat-desc .swiper-container .swiper-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -.75rem;
	position: static;
	transform: none !important;
}

.cat-desc .swiper-container .swiper-button {
	display: none;
}

.cat-desc .strip-item {
	padding-bottom: .75rem;
	position: static !important;
}

.strip-wrap::before,
.strip-wrap::after {
	bottom: 0;
	content: "";
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 1.5rem;
	z-index: 1;
}

.strip-wrap::before {
	background: linear-gradient(90deg, #fff 25%, rgba(255, 255, 255, 0));
	left: -.75rem;
}

.strip-wrap::after {
	background: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff 75%);
	right: -.75rem;
}

.strip-wrap {
	margin: 0 -.75rem 1rem -.75rem;
	position: relative;
	scrollbar-width: none;
}

.strip-wrap:focus {
	box-shadow: none;
}

.strip-wrap::-webkit-scrollbar {
	display: none;
}

.strip-wrap-extended::before,
.strip-wrap-extended::after {
	content: none;
}

.strip-wrap-extended {
	margin-bottom: -.75rem;
	margin-right: 0;
}

.strip-wrap-extended .strip-list::after {
	content: "";
	flex-grow: 99999;
}

.strip-wrap-extended.strip-wrap .strip-list {
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
}

.strip-wrap-extended .strip-item {
	flex: 1 0 50%;
	max-width: 50%;
	padding-bottom: .75rem;
}

.strip-wrap-extended .strip-link {
	align-items: center;
	display: flex;
}

.strip-wrap-extended .strip-img + .strip-link-text {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	margin: 0 .25rem;
	max-width: none;
	overflow: hidden;
	text-align: left;
}

.strip-wrap-extended .strip-item:last-child {
	padding-right: 0;
}

.strip-wrap-spaced::before,
.strip-wrap-spaced::after {
	width: .75rem;
}

.strip-wrap-spaced::before {
	left: 0;
}

.strip-wrap-spaced::after {
	right: 0;
}

.strip-wrap-spaced {
	width: calc(100% + 1.5rem);
}

.strip-wrap-spaced .strip-item {
	flex-basis: 0;
	text-align: center;
}

.strip-wrap-collapsed {
	height: 9.25rem;
	overflow: hidden;
}

.strip-list {
	display: flex;
}

.strip-item {
	list-style: none;
	padding-left: .75rem;
	position: relative;
	width: auto;
}

.strip-item:last-child {
	padding-right: .75rem;
}

.strip-link {
	background: #fff;
	border: thin solid #dee2e6;
	border-radius: .125rem;
	color: #464646;
	display: inline-block;
	font-size: .875rem;
	min-height: 2.25rem;
	padding: .375rem .5rem;
	transition: border-color .15s ease-in-out;
	vertical-align: top;
	white-space: nowrap;
}

.strip-link.active,
.strip-link[href]:hover {
	border-color: #aaaaed;
	color: #464646;
	text-decoration: none;
}

.strip-link-img {
	border-radius: 50%;
	overflow: hidden;
	padding: .5rem;
}

.strip-img {
	display: inline-block;
	height: 3rem;
	object-fit: cover;
	text-align: center;
	width: 3rem;
}

.strip-link-img .strip-img {
	object-fit: contain;
}

.strip-link-cat::before {
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.strip-link-cat > .strip-img {
	height: 4rem;
	width: 4rem;
}

.strip-link-cat,
.shm-box-img-link {
	background: rgba(98, 98, 249, .075);
}

.strip-link-cat > .strip-img,
.shm-box-img.shm-box-img {
	mix-blend-mode: darken;
	object-fit: contain;
}

body.samsung .strip-link-cat[href] {
	background: #fff;
	border-color: #dee2e6;
}

body.samsung .shm-box-img-link {
	background: #fff;
	box-shadow: 0 1px #dee2e6;
}

body.samsung .strip-link-cat > .strip-img,
body.samsung .shm-box-img.shm-box-img {
	mix-blend-mode: normal;
	padding: 0;
}

.strip-link-cat[href] {
	border-color: rgba(170, 170, 237, 0.25);
}

.strip-link-cat[href]:hover {
	border-color: rgba(170, 170, 237, 1);
}

.strip-link-cat-text {
	line-height: 1.25;
	margin-top: .5rem;
	text-align: center;
}

.strip-link-text {
	font-size: .8125rem;
}

.strip-img + .strip-link-text {
	display: inline-block;
	line-height: 1.25;
	margin-left: .125rem;
	max-height: 3rem;
	max-width: 8rem;
	text-align: center;
	vertical-align: middle;
	white-space: normal;
	width: max-content;
}

.strip-multi-text {
	min-width: 7rem;
	width: min-content;
}

.shm-outer-header,
.breadcrumb {
	background: #fff;
}

.shm-outer-header,
.shm-compare-container {
	align-items: center;
	display: flex;
}

.shm-outer-header {
	flex-wrap: wrap;
}

.shm-compare-container {
	margin-left: auto;
	padding: .5rem .5rem 0;
}

.breadcrumb {
	align-items: center;
	border-radius: 0;
	flex-wrap: nowrap;
	font-size: .8125rem;
	line-height: 1;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	white-space: nowrap;
}

.shm-breadcrumb {
	margin-bottom: 0;
	padding: .5rem .25rem 0;
	width: 100%;
}

.breadcrumb-item + .breadcrumb-item::before,
.filter-link-simple::after {
	align-self: center;
	content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%235858dc' d='M5.5 12.5L10 8 5.5 3.5'/%3e%3c/svg%3e");
	display: inline-flex;
	flex-shrink: 0;
	height: 1rem;
	padding-right: 0;
	width: 1rem;
}

.breadcrumb-item + .breadcrumb-item {
	padding-left: 0;
}

.breadcrumb-item > * {
	color: #677078;
	padding: .5rem;
}

.breadcrumb-link {
	color: #343a40;
}

.breadcrumb-link:hover {
	color: #5858dc;
	text-decoration: none;
}

.custom-control-label,
a.custom-control-label:hover {
	color: inherit;
	text-decoration: inherit;
}

a.custom-control-label:hover {
	transition: color .15s ease-in-out;
}

.custom-control-label-truncate {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	overflow: hidden;
	word-break: break-all;
}

.filter-link-simple::after {
	filter: brightness(0);
	left: 0;
	margin: .375rem 0;
	opacity: .35;
	position: absolute;
	top: 0;
}

.filter-link-simple {
	cursor: pointer;
	padding-left: 1.25rem !important;
}

.filter-link-simple .custom-control-label::before,
.filter-link-simple .custom-control-label::after {
	content: none;
}

.filter-link:hover .custom-control-label::before {
	border-color: #aaaaed;
}

.filter-link-simple .custom-control-label,
.filter-link:hover .custom-control-label {
	color: #5858dc;
}

.filter-link-simple:hover .custom-control-label {
	color: #4b4bbd;
}

.filter-link-simple.filter-is-checked .custom-control-label {
	font-weight: 500;
}

.btn,
.custom-select {
	border-radius: .125rem;
	box-shadow: none !important;
	font-size: .875rem;
	min-height: 2.25rem;
}

.custom-select,
.custom-select:focus {
	border-color: #ced4da;
}
/*
.custom-select:hover {
	border-color: #aaaaed;
}
*/
.custom-select-sm,
.custom-select-sm + .dropdown-menu .dropdown-item {
	font-size: .8125rem;
	min-height: 0;
}

.custom-select::after,
.custom-toggle::after {
	content: none;
}

.custom-select,
.custom-toggle {
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath fill='none' stroke='%23adb5bd' d='M3.5 5.5L8 10l4.5-4.5'/%3e%3c/svg%3e") no-repeat right .5rem center / 1rem 1rem !important;;
	cursor: pointer;
	transition: border-color .15s ease-in-out;
}

.custom-toggle {
	background-color: transparent;
	border: 0;
	color: inherit;
	display: inline-block;
	font-size: .8125rem;
	padding: .75rem 1.75rem .75rem 1rem;
}

.custom-toggle:hover {
	text-decoration: none;
}

.custom-select {
	padding-right: 1.75rem;
}

.custom-select-sm option {
	font-size: .75rem;
	padding: 0;
}

.show > .custom-toggle {
	color: #5858dc;
}

.link-dotted,
.link-dotted:hover,
.has-link-dotted a,
.has-link-dotted a:hover {
	border-bottom: thin dotted rgba(0, 0, 0, .5);
	color: inherit;
	text-decoration: none;
}

.link-dotted.link-dotted-light {
	border-bottom-color: rgba(0, 0, 0, .25);
}

.link-dotted:hover,
.has-link-dotted a:hover {
	border-bottom-style: solid;
}

.btn {
	align-items: center;
	display: inline-flex;
	justify-content: center;
	padding: .375rem 1rem;
	transition: background-color .15s ease-in-out, border-color .15s ease-in-out, color .15s ease-in-out;
}

.btn-sm {
	padding-bottom: .25rem;
	padding-top: .25rem;
}

.btn-sm.btn-clear {
	padding-bottom: .1875rem;
	padding-top: .1875rem;
}

.btn > .svg-icon {
	flex-shrink: 0;
	margin: .0625rem .375rem;
}

.btn > .svg-icon-sm {
	margin-bottom: .1875rem;
	margin-top: .1875rem;
}

.btn > .svg-icon:first-child {
	margin-left: 0;
}

.btn > .svg-icon:last-child {
	margin-right: 0;
}

a,
.btn-link,
.btn-clear {
	color: #5858dc;
}

.btn-clear {
	border: 0;
	padding: .375rem;
}

a:hover,
.btn-link:hover,
.btn-clear:hover {
	color: #4b4bbd;
}

.btn-clear > .svg-icon {
	margin: 0;
}

.color-gray,
.btn-color-gray {
	color: #adb5bd;
}

.font-size-sm {
	font-size: .8125rem;
}

.text-gray,
.btn-color-gray:hover {
	color: #6c757d;
}

.btn-grid,
.btn-list {
	min-height: 0;
}

.btn-grid.active,
.btn-list.active {
	color: #5858dc;
	pointer-events: none;
}

.btn-country-select::after {
	content: none;
}

.btn-country-select {
	border-color: rgba(255, 255, 255, .25);
	color: rgba(255, 255, 255, .75);
	font-size: .8125rem;
	margin-left: .75rem;
	min-height: 0;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
}

.btn-country-select:hover,
.show > .btn-country-select {
	border-color: rgba(255, 255, 255, .5);
	color: rgba(255, 255, 255, 1);
}

.btn-primary,
.btn-primary:focus,
.btn-primary:disabled,
.btn-primary.disabled {
	background-color: #6464f3;
	border-color: #6464f3;
}

.btn-primary:hover {
	background-color: #5858dc;
	border-color: #5858dc;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
	background-color: #4b4bbd;
	border-color: #4b4bbd;
}

.btn-brand,
.btn-brand:focus,
.btn-brand:disabled,
.btn-brand.disabled {
	background-color: #5858dc;
	border-color: #5858dc;
}

.btn-brand:hover {
	background-color: #4b4bbd;
	border-color: #4b4bbd;
}

.btn-brand:not(:disabled):not(.disabled):active,
.btn-brand:not(:disabled):not(.disabled).active {
	background-color: #3f3fb1;
	border-color: #3f3fb1;
}

.btn-brand-alt,
.btn-brand-alt:focus,
.btn-brand-alt:disabled,
.btn-brand-alt.disabled {
	background-color: #e6007d;
	border-color: #e6007d;
}

.btn-brand-alt:hover {
	background-color: #f3008a;
	border-color: #f3008a;
}

.btn-brand-alt:not(:disabled):not(.disabled):active,
.btn-brand-alt:not(:disabled):not(.disabled).active {
	background-color: #e7007e;
	border-color: #e7007e;
}

.btn-gray,
.btn-gray:focus,
.btn-gray:disabled,
.btn-gray.disabled {
	background-color: #f8f9fa;
	border-color: #dee2e6;
}

.btn-gray:hover {
	background-color: #f1f3f5;
	border-color: #dee2e6;
}

.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active {
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.btn-outline-primary,
.btn-outline-primary:focus,
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
	border: thin solid #6464f3;
	color: #6464f3;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
	background-color: #6464f3;
	border-color: #6464f3;
	color: #fff;
}

.btn-outline-pink,
.btn-outline-pink:focus,
.btn-outline-pink:disabled,
.btn-outline-pink.disabled {
	border: thin solid #e6007d;
	color: #e6007d;
}

.btn-outline-pink:hover,
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled).active {
	background-color: #e6007d;
	border-color: #e6007d;
	color: #fff;
}

.btn-outline-white,
.btn-outline-white:focus,
.btn-outline-white:disabled,
.btn-outline-white.disabled {
	border: thin solid #fff;
	border-radius: 0;
	color: #fff;
}

.btn-outline-white:hover,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled).active {
	background-color: #fff;
	border-color: #fff;
	color: #5858dc;
}

.btn-show-more-filters {
	display: inline-block;
	font-size: .8125rem;
	margin: .25rem 1rem;
	min-height: 0;
}

.modal-custom-details:last-child .btn-show-more-filters {
	margin-bottom: .5rem;
}

.btn-show-more-filters,
.btn-show-more-filters:hover {
	color: #6c757d;
}

.dropdown-menu {
	border: 0;
	box-shadow: 0 .25rem .75rem .25rem rgba(73, 80, 87, .15);
	margin-top: 0;
	min-width: 100%;
}

.dropdown-item {
	font-size: .875rem;
	padding: .25rem 1rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
	background: none;
}

.dropdown-item:hover {
	color: #5858dc;
}

.dropdown-item.active,
.dropdown-item:active {
	background: #ebebff;
	color: #5858dc;
	text-decoration: none;
}

.prod-list-wrap {
	margin-left: -.75rem;
	margin-right: -.75rem;
}

.prod-list {
	border-color: #dee2e6;
	border-style: solid;
	border-width: thin 0;
	display: grid;
	grid-gap: 1px;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.prod-list-index {
	display: flex;
	margin: .5rem 0;
	overflow: initial;
}

.btn-prod-list-index {
	font-size: 1.125rem;
	white-space: nowrap;
	width: min-content;
}

.prod-card-grid {
	grid-template-columns: repeat(2, 50%);
}

.prod-card-list {
	grid-template-columns: 1fr;
}

.prod-item,
.prod-item-content-wrap,
.prod-item-content {
	min-width: 0;
}

.prod-item {
	background: #fff;
	box-shadow: 0 0 0 1px #dee2e6;
	display: flex;
	height: auto;
	padding: 1rem;
	position: relative;
}

.card-index {
	flex-direction: row;
}

.card-index-text {
	flex-basis: 50%;
	flex-direction: column;
	flex-shrink: 0;
	max-width: 50%;
}

.card-index .card-index-text {
	background: #c3fae8; /* light green */
}

.card-index + .card-index .card-index-text {
	background: #ffe3e3; /* light red */
}

.card-index + .card-index + .card-index .card-index-text {
	background: #c5f6fa; /* light blue */
}

.card-index .swiper-container-prods .prod-list .prod-item {
	flex-basis: 100%;
	max-width: 100%;
}

.banner-icon {
	height: 3rem;
	width: 3rem;
}

.h4,
.banner-title,
.card-index-title {
	font-size: 1.125rem;
}

.prod-card-grid .prod-item {
	flex-direction: column;
	padding-bottom: 3rem;
}

.prod-item-options {
	line-height: 1;
	margin: .5rem;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.prod-card-list .prod-item-options {
	bottom: 0;
	display: flex;
	top: auto;
}

.prod-card-list .prod-item-options .prod-option {
	padding: .25rem .5rem;
}

.prod-card-grid .prod-item-options .prod-option {
	padding: .5rem;
}

.prod-option::after {
	opacity: .25;
}

.prod-option {
	background: rgba(255, 255, 255, .9);
	border-radius: 2em;
	color: #adb5bd;
	display: flex;
	min-height: 0;
	min-width: 0;
	transition: background-color .15s ease-in-out, color .15s ease-in-out;
	white-space: nowrap;
}

.prod-option:hover {
	background: rgba(255, 255, 255, 1);
	color: #6c757d;
}

.prod-page-info .prod-option.loading::after {
	background-position: -.3125rem 50%;
	background-size: 2rem 2rem;
}

.prod-page-info .prod-option {
	border: 0;
	color: #6c757d;
	margin-left: -.5rem;
	padding-left: .5rem;
	padding-right: .5rem;
	width: auto !important;
}

.prod-page-info .prod-option:hover {
	color: #6c757d;
}

.prod-option.prod-is-fav .svg-icon,
.prod-option.prod-is-compared .svg-icon {
	color: #6464f3;
}

.prod-option-favorite .icon-heart-filled {
	transform: scale(0);
	transform-origin: 50% 50%;
	transition: transform .25s;
}

.prod-option-favorite.prod-is-fav .icon-heart-filled {
	transform: scale(1);
	transition-duration: .5s;
}

.prod-option-favorite-clone {
	align-items: center;
	background: none;
	border-radius: 0;
	color: #6464f3;
	justify-content: center;
	padding: 0;
	pointer-events: none;
	position: absolute;
	z-index: 10000;
	/* transform: scale(.75); */
}

.prod-item-discount {
	align-items: center;
	background: #e6007d;
	border-radius: 50%;
	color: #fff;
	display: flex;
	font-size: .8125rem;
	font-weight: 500;
	height: 2.5rem;
	justify-content: center;
	left: 0;
	margin: .5rem;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 2.5rem;
	z-index: 1;
}

.prod-card-grid .prod-item-img-wrap {
	margin-bottom: .75rem;
}

.prod-card-list .prod-item-img-wrap {
	flex-basis: 30%;
	min-width: 30%;
}

.prod-page-img,
.prod-item-img {
	object-fit: scale-down;
}

.prod-item-img {
	object-position: center top;
}

.prod-item-content-wrap {
	display: flex;
	flex-direction: column;
}

.prod-item-content {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.prod-card-list .prod-item-content,
.prod-card-list .prod-item-content-extra {
	margin: 0 1rem;
}

.prod-item-content .rating {
	margin: .125rem 0;
}

.prod-item-name,
.prod-desc {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.prod-item-name {
	font-size: 1rem;
	font-weight: 500;
	-webkit-line-clamp: 2;
}

.prod-item-name,
.prod-item-name:hover {
	color: inherit;
	text-decoration: none;
}

.prod-item-content h2 {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: .25rem;
}

.prod-desc {
	font-size: .875rem;
	-webkit-line-clamp: 3;
	margin: .75rem 0;
}

.prod-list .prod-desc {
	margin: .375rem 0;
}

.prod-desc span {
	color: #6c757d;
}

.prod-item-info {
	margin-top: auto;
}

.prod-interval,
.prod-offers {
	color: #6c757d;
	font-size: .8125rem;
	white-space: nowrap;
}

.prod-offers {
	font-size: .875rem;
}

.prod-price,
.offer-price,
.prod-price-before {
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
}

.prod-price,
.offer-price,
.prod-price-lite {
	color: #e6007d;
}

.prod-price {
	font-size: 1.0625rem;
}

.prod-price-lite {
	font-size: .875rem;
	font-weight: 500;
}

.prod-price-superlite {
	color: #343a40;
}

.prod-price sup,
.offer-price sup {
	top: -.3333em;
}

.prod-price-lite sup,
.prod-price-superlite sup {
	top: -.3em;
}

.prod-price-before::before {
	background: currentColor;
	content: "";
	height: 1px;
	left: -.25rem;
	opacity: .75;
	position: absolute;
	right: -.25rem;
	top: calc(50% - 1px);
}

.prod-price-before {
	color: #6c757d;
	margin-left: .75rem;
	position: relative;
}

.prod-item-shipping {
	color: #6c757d;
	font-size: .8125rem;
	letter-spacing: -.3333px;
	margin-bottom: .25rem;
}

.prod-item-shipping .svg-icon,
.offer-shipping .svg-icon {
	color: #adb5bd;
	margin-right: .25rem;
}

.prod-item-shipping .prod-sep {
	color: #adb5bd;
	margin: 0 .125rem;
}

.prod-item-merchant-wrap {
	margin-top: .375rem;
}

.prod-card-grid .prod-item-merchant-wrap {
	position: absolute;
}

.prod-item-merchant-link {
	align-items: center;
	color: #495057;
	display: inline-flex;
	font-size: .8125rem;
	line-height: 1rem;
	margin-left: 1.3125rem;
	margin-right: 1rem;
	position: relative;
	text-align: left;
	word-break: break-all;
	z-index: 1;
}

.prod-item-merchant-img {
	height: 1rem;
	margin-left: -1.3125rem;
	overflow: hidden;
	position: absolute;
	width: 1rem;
}

.swiper-wrapper {
	height: auto !important;
	margin-bottom: 0;
}

.swiper-container-prods::after {
	border-radius: inherit;
	bottom: 0;
	box-shadow: 0 0 0 1px #fff inset;
	content: "";
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.swiper-container-prods .prod-list {
	border: 0;
	display: flex;
	grid-gap: 0;
}

.swiper-container-prods .prod-list .prod-item {
	box-shadow: 0 0 0 1px #e9ecef;
	flex-basis: 50%;
	max-width: 50%;
	padding-bottom: 2rem;
}

.rating {
	display: flex;
	font-size: 1rem;
}

.rating-xs,
.tooltip-inner {
	font-size: .75rem;
}

.rating-sm {
	font-size: .875rem;
}

.rating-md {
	font-size: 1.125rem;
}

.rating-lg {
	font-size: 1.25rem;
}

.icon-star,
.rating-stars::before {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath d='M8 .372c-.667 1.88-1.33 3.75-2 5.63H.63c1.46 1.1 2.92 2.2 4.38 3.31-.521 1.93-1.04 3.87-1.56 5.8 1.52-1.16 3.03-2.32 4.55-3.48 1.52 1.16 3.04 2.32 4.55 3.48-.522-1.93-1.04-3.87-1.57-5.8 1.46-1.1 2.92-2.2 4.38-3.31H9.99c-.667-1.88-1.33-3.75-2-5.63z' fill='%23fab005'/%3e%3c/svg%3e");
}

.icon-star {
	background-position: 50% 50%;
	background-size: .75rem;
	height: 1em;
	width: 1em;
}

.rating-stars::before {
	background-position: 0 0;
	background-size: 1em;
	content: "";
	display: block;
	height: 100%;
	width: var(--rating, 0);
}

.rating-stars {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='16' height='16'%3e%3cpath d='M8 .372c-.667 1.88-1.33 3.75-2 5.63H.63c1.46 1.1 2.92 2.2 4.38 3.31-.521 1.93-1.04 3.87-1.56 5.8 1.52-1.16 3.03-2.32 4.55-3.48 1.52 1.16 3.04 2.32 4.55 3.48-.522-1.93-1.04-3.87-1.57-5.8 1.46-1.1 2.92-2.2 4.38-3.31H9.99c-.667-1.88-1.33-3.75-2-5.63z' fill='%23ced4da'/%3e%3c/svg%3e") 0 0 / 1em;
	display: inline-block;
	height: 1em;
	width: 5em;
}

.rating-count {
	color: #6c757d;
	font-size: .75em;
	margin-left: .25em;
}

.modal-backdrop {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.modal.modal-custom {
	opacity: 1;
}

.modal.modal-custom .modal-dialog {
	margin: 0;
}

.modal.modal-offcanvas .modal-dialog {
	transform: translateX(-100%);
	transition: transform .15s ease-in-out;
}

.modal.modal-offcanvas .modal-dialog.transition-none {
	transition: none !important;
}

.modal.modal-custom.show .modal-dialog {
	transform: translateX(0);
}

.modal-custom .modal-content,
.modal-custom .modal-header,
.modal-custom .modal-footer {
	border: 0;
}

.modal-custom .modal-content {
	border-radius: 0;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

.modal-custom .modal-header {
	border-bottom: thin solid #dee2e6;
}

.modal-filters {
	padding-left: 0 !important;
}

.modal-filters .modal-content {
	background: #f8f9fa;
	padding-bottom: 4rem;
}

.modal-filters .modal-header,
.modal-filters .modal-footer {
	background: #f1f3f5;
}

.modal-filters .modal-header {
	min-height: 3rem;
}

.modal-filters .modal-footer {
	border-top: thin solid #dee2e6;
	bottom: 0;
	height: 4rem;
	left: 0;
	position: fixed;
	right: 0;
	z-index: 2;
}

.modal-menu .modal-title,
.modal-custom-title {
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
}

.modal-custom .close {
	color: #495057;
	line-height: 0;
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.modal-custom .modal-body {
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	padding: 0;
}

/* prevent tooltips to add scrollbars */
/*
.modal-custom-details {
	overflow-x: hidden;
}
*/
/*
.modal-custom-title,
.modal-custom-details > .modal-custom-item,
.modal-custom-summary {
	text-transform: capitalize;
}
*/
.modal-custom-details > .modal-custom-details {
	margin-bottom: 0;
	margin-left: .75rem;
	margin-right: .75rem;
}

.modal-custom-summary {
	border-bottom: thin solid #e9ecef;
	font-size: .875rem;
	font-weight: 500;
	padding: .5rem 2.25rem .5rem 1rem;
}

.modal-custom-summary-lg {
	border-color: #5858dc;
	font-size: 1rem;
	font-weight: 400;
	text-transform: uppercase;
}

.custom-control-wrap {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	margin: .375rem 0;
}

.modal-custom-details[open] > .modal-custom-details > .modal-custom-summary {
	font-size: .875rem;
	font-weight: 400;
}

.modal-custom-details[open] > .modal-custom-details:first-child > .modal-custom-summary {
	margin: -.5rem 0 0;
}

.modal-custom-item,
.modal-custom-item:hover {
	align-items: center;
	color: inherit;
	display: flex;
	font-size: .875rem;
	margin-bottom: 0;
	padding: .1875rem 1rem;
	text-decoration: none
}

.modal-custom-item:active {
	background: transparent;
}

.modal-custom-item.active,
.modal-custom-item.active:hover {
	color: #5858dc;
}

.modal-custom-details[open] .modal-custom-item {
	font-weight: 400;
	margin-left: .75rem;
	margin-right: .75rem;
}

.modal-custom-details[open] > .modal-custom-details .modal-custom-item {
	color: #6c757d;
}

.modal-custom-details .modal-custom-item.custom-control {
	padding-left: 1.75rem;
	padding-right: .5rem;
}

.modal-custom-item .custom-control-label::before,
.modal-custom-item .custom-control-label::after {
	top: 50%;
}

.modal-custom-item .custom-control-label::before {
	border-radius: 0;
	height: 1rem;
	transform: translateY(-50%);
	width: 1rem;
}

.modal-custom-item .custom-control-label::after {
	transform: translateY(calc(-1px - 50%)) scale(0);
	transition: transform .15s ease-in-out;
}

.modal-custom-item .custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none;
	border-color: rgba(0, 0, 0, .25);
}

.filter-item .filter-is-checked .custom-control-label::before,
.modal-custom-item .custom-control-input:checked ~ .custom-control-label::before,
.modal-custom-item .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
	background: #fff;
	border-color: #6464f3;
}

.filter-item .filter-is-checked .custom-control-label::after,
.modal-custom-item .custom-control-input:checked ~ .custom-control-label::after {
	background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' width='14' height='14'%3e%3cpath fill='%236464f3' d='M11 3L5.5 8.5l-2-2L2 8l3.5 3.5 7-7z'/%3e%3c/svg%3e") no-repeat 50% 50% / 100% 100%;
	height: calc(1rem - 2px);
	margin: 1px;
	transform: translateY(calc(-1px - 50%)) scale(1);
	width: calc(1rem - 2px);
}

.filter-type-single .custom-control-label::before,
.filter-type-single .custom-control-label::after {
	border-radius: 50%;
}

.filter-type-single.filter-is-checked .custom-control-label::after {
	background-clip: content-box !important;
	background: #6464f3;
	padding: .25rem;
}

.modal-custom-item .custom-control-label + .custom-control-count::before {
	content: "";
	margin-left: .25rem;
}

.modal-custom-item .custom-control-count {
	color: #6c757d;
	font-size: .625rem;
}

.modal-custom-item .custom-checkbox-input:active {
	filter: none;
}

.modal-custom-item-lg,
.modal-custom-item-lg:hover {
	padding: .5rem 1rem;
	align-self: stretch;
	border-bottom: thin solid #e9ecef;
}

.modal-custom .modal-footer > * {
	margin: 0;
}

.prod-page-wrap {
	display: grid;
	grid-template-areas: "prod-page-head" "prod-page-slider" "prod-page-info";
	grid-template-columns: 100%;
	grid-template-rows: auto auto auto;
}

.prod-page-head {
	display: flex;
	flex-wrap: wrap;
	grid-area: prod-page-head;
}

.prod-page-slider {
	grid-area: prod-page-slider;
	padding: .5rem 0;
}

.prod-page-info {
	grid-area: prod-page-info;
	margin-bottom: 1rem;
}

.prod-page-title {
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
	font-size: 1rem;
}

.prod-page-rating,
.site-page-rating {
	align-items: center;
	display: flex;
	margin: .75rem 0;
}

.prod-page-wrap .prod-price {
	font-size: 1.25rem;
}

.prod-page-wrap .prod-interval {
	margin: 1rem 0 .5rem;
}

.prod-page-wrap .prod-out-of-stock {
	background: #adb5bd;
	border-radius: 50rem;
	color: #fff;
	display: inline-block;
	font-size: .8125rem;
	margin-top: 1rem;
	padding: .375rem 1rem;
}

.prod-page-chart {
	display: inline-block;
	transition: opacity .3s ease-in-out, transform .3s ease-in-out;
}

.prod-page-chart:not(.show) {
	opacity: 0;
	transform: scaleY(0);
}

.prod-page-chart > svg {
	overflow: visible;
}

.prod-page-chart > svg > path[clip-path*="-positive"] {
	clip-path: none;
}

.prod-page-options {
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: .5rem;
	position: absolute;
	right: 0;
	top: 0;
}

.prod-page-options .prod-option {
	padding: .5rem;
}

.prod-page-options .prod-option .svg-icon {
	height: 1.25rem;
	width: 1.25rem;
}

.pagination {
	margin: 0;
}

.page-item + .page-item {
	margin-left: .75rem;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border: thin solid #6c757d;
	border-radius: 50%;
	font-size: .875rem;
}

.page-item:not(.page-item-empty):first-child .page-link:hover,
.page-item:not(.page-item-empty):last-child .page-link:hover {
	border-color: #495057;
}

.page-item:not(.page-item-empty) .page-link:hover {
	font-weight: 500;
}

.page-link,
.page-link:hover {
	background: none;
	border: 0;
	color: #343a40;
}

.page-link {
	align-items: center;
	display: flex;
	font-size: 1rem;
	height: 2.25rem;
	justify-content: center;
	min-width: 2.25rem;
	padding: .5rem;
}

.page-link:focus {
	box-shadow: none;
}

.page-item-active > .page-link,
.page-item-empty > .page-link {
	cursor: default;
}

.page-item-active > .page-link {
	box-shadow: 0 2px #e6007d;
	font-weight: 500;
}

.page-item-empty > .page-link {
	opacity: .5;
}

.btn-filter-wrap {
	align-items: baseline;
	display: flex;
	margin-bottom: .5rem;
	overflow-x: auto;
	scrollbar-width: none;
	width: 100%;
}

.btn-filter-wrap::-webkit-scrollbar {
	display: none;
}

.btn-toggle-filters {
	color: #495057;
	margin-bottom: .125rem;
	position: relative;
	top: -.25rem;
}

.btn-toggle-filters.has-filters {
	color: #5858dc;
}

.btn-filter,
.btn-filter-clear {
	white-space: nowrap;
}

.btn-filter {
	align-items: baseline;
	background: #eeeefe;
	border-radius: .25rem;
	color: #464646;
	font-size: .8125rem;
	margin: 0 .5rem .5rem 0;
	min-height: 0;
	padding: .25rem .375rem;
}

.btn-filter-clear {
	font-size: .8125rem;
	order: 1;
}

.btn-filter:hover,
.btn-filter-clear:hover ~ .btn-filter {
	background: #ebebff;
	color: #464646;
	text-decoration: line-through #5858dc;
}

.btn-filter-clear:hover {
	text-decoration: none;
}

.btn-filter > .svg-icon {
	color: #6c757d;
	height: .625rem;
	width: .625rem;
}

.btn-filter:hover > .svg-icon,
.btn-filter:focus > .svg-icon,
.btn-filter-clear:hover ~ .btn-filter > .svg-icon {
	color: #5858dc;
}

a.btn-share,
a.btn-share:visited,
a.btn-share:hover,
a.btn-share:active {
	border-radius: 100%;
	box-sizing: content-box;
	display: inline-block;
	font-weight: 500;
	width: 28px;
	height: 28px;
	line-height: 26px;
	min-height: auto;
	padding: 0;
	text-decoration: none!important;
	vertical-align: top;
}

a.btn-share:hover,
a.btn-share:active {
	text-decoration: none!important;
}

.shm-box-wrap {
	display: grid;
	grid-gap: .75rem;
	grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
}

.shm-box {
	border: thin solid #dee2e6;
}

.shm-box-link,
.shm-box-link:hover {
	color: #495057;
}

.shm-box-img-link::before {
	padding-top: 75%;
}

.shm-box-img-link {
	align-items: center;
	display: flex;
}

.shm-box-img-link:hover ~ .shm-box-info > .shm-box-title-link,
.shm-box-title-link:hover {
	text-decoration: underline;
}

.shm-box-img.shm-box-img {
	max-height: none;
	padding: 1rem;
}

.shm-box-title {
	font-size: 1rem;
	font-weight: 400;
	margin: 0;
	overflow-wrap: normal;
	padding: 1rem 0 .5rem;
}

.shm-box-info {
	padding: 0 1rem;
}

.shm-box-list {
	list-style: none;
	margin: 0 0 1rem;
	padding: 0;
}

.shm-box-list-item h3,
.offer-row h3 {
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin: 0;
}

.shm-box-list-link {
	display: inline-block;
	padding: .25rem 0;
}

.brand-social-wrap {
	display: flex;
	padding: .5rem;
	position: absolute;
	right: 0;
	top: 0;
}

.brand-social {
	color: #495057;
	padding: .5rem;
}

.bg-brand {
	background: #6464f3;
}

.bg-brand-lighter {
	background: #e8e8ff;
}

.bg-brand-alt {
	background: #e6007d;
}

.bg-apple {
	background: #495057;
}

.bg-facebook {
	background: #1877f2;
}

.bg-google {
	background: #4285f4;
}

.bg-instagram {
	background: #e4405f;
}

.bg-pinterest {
	background: #bd081c;
}

.bg-twitter {
	background: #1da1f2;
}

.bg-whatsapp {
	background: #25d366;
}

.bg-youtube {
	background: #f00;
}

.bullet {
	background: currentColor;
	border-radius: 50%;
	display: inline-block;
	height: .5rem;
	width: .5rem;
}

.bullet.color-white {
	border: 1px solid #ced4da;
}

.color-blue   { color: #1c7ed6; }
.color-gray   { color: #adb5bd; }
.color-green  { color: #37b24d; }
.color-orange { color: #f76707; }
.color-red    { color: #f03e3e; }
.color-yellow { color: #f59f00; }

.color-dark-blue   { color: #1864ab; }
.color-dark-gray   { color: #6c757d; }
.color-dark-green  { color: #2b8a3e; }
.color-dark-orange { color: #d9480f; }
.color-dark-red    { color: #c92a2a; }
.color-dark-yellow { color: #e67700; }

.color-light-blue   { color: #339af0; }
.color-light-gray   { color: #ced4da; }
.color-light-green  { color: #51cf66; }
.color-light-orange { color: #ff922b; }
.color-light-red    { color: #ff6b6b; }
.color-light-yellow { color: #fcc419; }

.color-brand {
	color: #6464f3;
}

.color-brand-alt {
	color: #e6007d;
}

.color-white,
.color-white-hover:hover {
	color: #ffffff;
}

.color-apple,
.color-apple-hover:hover {
	color: #495057;
}

.color-facebook,
.color-facebook-hover:hover {
	color: #1877f2;
}

.color-google,
.color-google-hover:hover {
	color: #4285f4;
}

.color-instagram,
.color-instagram-hover:hover {
	color: #e4405f;
}

.color-pinterest,
.color-pinterest-hover:hover {
	color: #bd081c;
}

.color-twitter,
.color-twitter-hover:hover {
	color: #1da1f2;
}

.color-whatsapp,
.color-whatsapp-hover:hover {
	color: #25d366;
}

.color-youtube,
.color-youtube-hover:hover {
	color: #f00;
}

.clamp,
.clamp-1,
.clamp-2,
.clamp-3 {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.clamp-1 {
	-webkit-line-clamp: 1;
}

.clamp-2 {
	-webkit-line-clamp: 2;
}

.clamp-3 {
	-webkit-line-clamp: 3;
}

.brand-desc-checkbox {
	opacity: 0;
	position: absolute;
	z-index: -1;
}

.brand-desc-wrap {
	align-self: flex-start;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	overflow: hidden;
	position: relative;
}

.brand-desc-checkbox:checked ~ .brand-desc-wrap {
	display: block;
}

.brand-desc-checkbox:checked ~ .brand-desc-wrap .brand-desc::before {
	content: none;
}

.brand-desc-checkbox:not(:checked) ~ .brand-desc-wrap .brand-desc p {
	margin: 0;
}

.brand-desc-checkbox:checked ~ .brand-desc-wrap .btn-show-more-desc {
	display: none;
}

.brand-desc::before {
	background: linear-gradient(rgba(255, 255, 255, 0) 50%, #fff 50%), linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, #fff);
	bottom: 0;
	content: "";
	height: 3rem;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
}

.brand-desc {
	border-bottom: 1.5rem solid transparent;
	font-size: .875rem;
	line-height: 1.25rem;
}

.brand-desc > :last-child {
	margin-bottom: 0;
}

.btn-show-more-desc {
	font-size: .8125rem;
	line-height: inherit;
	margin: 0;
	min-height: 0;
	padding: 0;
	position: absolute;
	top: 3.875rem;
}

.btn-show-more-desc,
.btn-show-more-desc:hover {
	color: #6c757d;
}

.noUi-target,
.noUi-handle,
.noUi-connect {
	border: none;
	box-shadow: none;
}

.noUi-target {
	background: #ebebff;
	border-radius: .125rem;
}

.noUi-handle::after,
.noUi-handle::before {
	content: none;
}

.noUi-handle {
	background: #6464f3;
}

.noUi-handle.focus-visible {
	box-shadow: 0 0 0 .25rem #e6007d inset !important;
}

.noUi-base::before {
	bottom: 0;
	content: "";
	cursor: pointer;
	left: 0;
	position: absolute;
	right: -1rem;
	top: 0;
}

.noUi-base {
	box-sizing: content-box;
	padding: 1rem 0;
	top: -1rem;
}

.noUi-connects {
	border-radius: 0;
}

.noUi-connect {
	left: .5rem;
	cursor: pointer;
}

.noUi-draggable {
	cursor: ew-resize;
}

.noUi-horizontal {
	height: .1275rem;
	margin: 1rem 0;
	padding-right: 1rem;
}

.noUi-horizontal .noUi-handle {
	border-radius: 50%;
	cursor: pointer;
	height: 1.0275rem;
	right: -1.0275rem;
	top: .55rem;
	width: 1.0275rem;
}

.noUi-horizontal .noUi-handle:active {
	cursor: ew-resize;
}

.noUi-touch-area {
	height: 200%;
	margin: -50%;
	width: 200%;
}

.noUi-tooltip {
	/* display: none; */
	/* width: 0; */
	opacity: 0;
	font-size: .75rem;
	font-weight: 500;
	pointer-events: none;
	transition: opacity .2s ease-in-out, transform .2s ease-in-out;
}

.noUi-handle-lower .noUi-tooltip {
	left: auto;
	right: 100%;
	transform: translateX(100%);
}

.noUi-handle-upper .noUi-tooltip {
	left: 100%;
	transform: translateX(-100%);
}

.noUi-active .noUi-tooltip {
	/* display: block; */
	/* width: unset; */
	background-color: rgba(255, 255, 255, .7);
	opacity: 1;
}

.noUi-connect,
.noUi-handle,
.noUi-connect {
	transition: box-shadow .2s ease-in-out, background-color .2s ease-in-out;
}

.slider-inactive .noUi-handle,
.slider-inactive .noUi-connect {
	background: #aaaaed;
}

.noUi-connect,
.slider-inactive .noUi-handle:hover {
	background: #6464f3;
}

.slider-wrap:not(.noUi-target) {
	min-height: 2.1875rem;
}

.slider-inactive .noUi-handle.focus-visible {
	box-shadow: 0 0 0 .25rem #c0a5b5 inset !important;
}

.slider-inputs .form-control {
	font-size: .75rem;
	height: 1.8rem;
	line-height: 1;
	padding: .4rem;
}

.slider-inputs {
	position: relative;
	z-index: 2;
}

.slider-wrap {
	position: relative;
	z-index: 1;
}

.slider-wrap.slider-has-graph {
	margin-top: 3rem;
}

.slider-graph {
	position: absolute;
	top: -50px;
	left: 8px;
	right: 0;
	width: calc(100% - 24px);
	height: 50px;
	z-index: 1;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

.slider-graph-item {
	position: absolute;
	bottom: 0;
	height: 100px;
	background-color: rgba(102, 102, 250, .25);
}

.col-main {
	overflow-x: hidden;
}

.swiper-button::after {
	font-size: 1.25rem;
}

.swiper-button-prev::after {
	margin-right: .25rem;
}

.swiper-button-next::after {
	margin-left: .25rem;
}

.swiper-button {
	background: #fff;
	border: thin solid #e9ecef;
	border-radius: 50%;
	box-shadow: 0 .5rem .75rem -.5rem rgba(73, 80, 87, .15);
	color: #6c757d;
	display: none;
	height: 2.5rem;
	opacity: .85;
	padding: .5rem;
	top: calc(50% + .125rem);
	transition: opacity .1s ease-in-out;
	width: 2.5rem;
}

.swiper-button-prev {
	left: .75rem;
}

.swiper-button-next {
	right: .75rem;
}

.swiper-button:not(.swiper-button-disabled):hover {
	opacity: 1;
}

.swiper-button.swiper-button-disabled {
	opacity: 0;
	pointer-events: auto;
}

.index-cat-list ~ .swiper-scrollbar {
	display: none;
}

.index-cat-item {
	flex-basis: 50%;
	max-width: 50%;
}

.index-cat-link {
	color: inherit;
	display: block;
}

.index-cat-link:hover {
	color: inherit;
	opacity: .95;
	text-decoration: none;
}

.index-cat-name {
	border-bottom: thin dotted rgba(0, 0, 0, .5);
	bottom: 0;
	font-size: 1rem;
	left: 0;
	margin: 1rem;
	max-width: calc(100% - 2rem);
	overflow: hidden;
	position: absolute;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.index-cat-link:hover .index-cat-name {
	border-bottom: thin solid rgba(0, 0, 0, .25);
}

.index-cat-img-wrap::before {
	padding-top: calc(344 / 293 * 100%);
}

.index-cat-img-wrap {
	background: #dee2e6;
}

.index-cat-link.focus-visible .index-cat-img-wrap {
	box-shadow: 0 0 0 2px #e6007d;
}

.map-list,
.map-list-cols,
.map-sublist {
	list-style: none;
	padding-left: 0;
}

.map-list {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -.5rem;
}

.map-list-cols > .map-item {
	break-inside: avoid;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
}

.map-list-cols {
	columns: 2;
}

.map-list > .map-item {
	flex-basis: 50%;
	max-width: 50%;
}

.map-list > .map-item:nth-child(2n+1) {
	padding-right: .5rem;
}

.map-list > .map-item:nth-child(2n) {
	padding-left: .5rem;
}

.map-link {
	align-items: flex-start;
	color: #495057;
	display: flex;
	font-size: 1rem;
	padding: .8125rem 0;
}

.map-link:hover {
	color: #212529;
	text-decoration: none;
}

.map-sublist .map-link {
	border-top: thin solid #e9ecef;
	color: #6c757d;
	font-size: .875rem;
}

.map-sublist .map-item:nth-last-child(2) .map-link {
	border-bottom: thin solid #e9ecef;
}

.map-sublist .map-link:hover {
	color: #495057;
}

.map-sublist .map-link-more {
	border-top: 0;
	color: #6c757d;
	display: inline-block;
	font-size: .8125rem;
	line-height: 2rem;
	padding: .5rem 0;
}

.map-sublist .map-link-more:hover {
	color: #6c757d;
}

.map-icon {
	height: 1.25rem;
	margin-left: .75rem;
	margin-top: .125rem;
	order: 1;
	width: 1.25rem;
}

.alphabet-list {
	background: #fff;
	display: flex;
	margin-bottom: 0;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	padding: 1rem 0;
}

.alphabet-item {
	flex-basis: 100%;
	margin: 0 .25rem;
}

.alphabet-link {
	align-items: center;
	border-radius: 50%;
	border: thin solid #dee2e6;
	box-shadow: 0 .5rem .5rem -.5rem rgba(73, 80, 87, .15);
	display: flex;
	font-size: .875rem;
	height: 2.25rem;
	justify-content: center;
	margin: auto;
	width: 2.25rem;
}

.alphabet-link,
.alphabet-link:hover {
	background: #f8f9fa;
	color: #343a40;
	text-decoration: none;
}

.alphabet-link:hover,
.alphabet-item.active .alphabet-link {
	border-color: #aaaaed;
}

.alphabet-item.active .alphabet-link {
	color: #5858dc;
}

.alphabet-link:hover {
	background: #fff;
}

.brands-title {
	font-size: 1rem;
	text-transform: uppercase;
}

.brands-list {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(auto-fill, calc((100% - 1rem) / 2));
}

.brands-link,
.brands-link:hover {
	color: #212529;
	text-decoration: none;
}

.brands-link {
	box-sizing: content-box;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 6.25rem;
	padding: .125rem;
	text-align: center;
}

.brands-link > .embed-responsive {
	transition: filter .1s ease-out, opacity .1s ease-out;
}

.brands-link:not(:hover) > .embed-responsive {
	filter: saturate(0);
	opacity: .85;
}

.brands-name {
	font-size: .875rem;
	font-weight: 400;
	line-height: 1.5;
	margin: 0;
}

.about-title {
	font-size: 1.25rem;
}

.about-text {
	font-size: 1rem;
	margin-bottom: 0;
}

.about-text + .about-text {
	margin-top: 1rem;
}

.embed-responsive-about-hero::before {
	padding-top: calc(175 / 1136 * 100%);
}

.embed-responsive-3by2::before {
	padding-top: calc(2 / 3 * 100%);
}

.footer {
	background: #313194;
	color: #fff;
	margin-top: auto;
	padding: 1rem 0;
}

.footer-title {
	color: #fff;
	font-size: .875rem;
	font-weight: 500;
	padding: .75rem 0;
	text-transform: uppercase;
}

.footer .nav-item > .nav-link {
	color: rgba(255, 255, 255, .8);
	font-size: .8125rem;
	min-width: 2rem;
	padding: .5rem 0;
	position: relative;
}

.footer-social-wrap {
	white-space: nowrap;
}

.footer-social-link {
	color: #fff;
	line-height: 1;
	margin-left: -.5rem;
	opacity: .6;
	padding: .5rem;
}

.footer-social-link:hover {
	color: #fff;
	opacity: .8;
	text-decoration: none;
}

.footer-social-link + .footer-social-link {
	margin-left: 0;
}

.footer-bottom {
	font-size: .75rem;
	color: rgba(255, 255, 255, .75);
}

.alert-cookies {
	background: rgba(0, 0, 0, .85);
	border-radius: 0;
	bottom: 0;
	box-shadow: 0 0 2rem rgba(0, 0, 0, .25);
	color: #fff;
	display: flex;
	flex-direction: column;
	font-size: .8125rem;
	left: 0;
	line-height: 1.25;
	margin: 0;
	padding: 1rem .75rem;
	position: fixed;
	right: 0;
	z-index: 10000;
}

.alert-cookies-content {
	margin-bottom: 1rem;
	-webkit-mask-image: linear-gradient(rgb(0, 0, 0) 75%, rgba(0, 0, 0, .25));
	max-height: 3rem;
	overflow-y: auto;
}

.alert-cookies-link {
	border-bottom: thin dotted;
	color: inherit;
}

.alert-cookies-link:hover {
	border-bottom-style: solid;
	color: inherit;
	text-decoration: none;
}

.alert-cookies-btn {
	min-height: 0;
	padding: .375rem;
}

.overlayer-thanks {
	align-items: center;
	background: rgba(255, 255, 255, .95);
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 2000;
}

.intro-content {
	width: 100%;
}

.shm-overlayer .intro-content {
	margin: 1rem;
	padding: 0;
	width: auto;
}

.intro-content .alert {
	margin-bottom: 1.25rem !important;
}

.intro-content .alert-error {
	background-color: #ffe3e3;
	border-color: #dc3545;
	color: #c92a2a;
}

.intro-logo-wrap {
	text-align: center;
}

.intro-logo-img {
	height: 3rem;
	width: 7.75rem;
}

.shm-overlayer .intro-logo-wrap {
	display: none;
}

.intro-logo-link {
	display: inline-block;
	padding: .75rem;
}

.intro-card.card {
	border-radius: 0;
}

.shm-overlayer .intro-card.card {
	border-radius: 0;
	box-shadow: none;
}

.intro-card-body.card-body {
	padding: 1rem;
}

.shm-overlayer .intro-card-body.card-body {
	padding: 0;
}

.intro-social-wrap {
	margin: 1rem 0;
}

.intro-social-btn {
	border-radius: 2rem;
	color: #fff;
	padding: .5rem;
	position: relative;
	transition: opacity .15s ease-in-out;
	width: 100%;
}

.intro-social-btn:hover {
	color: #fff;
	opacity: .95;
}

.intro-social-btn + .intro-social-btn {
	margin-top: .75rem;
}

.intro-social-btn > .svg-icon {
	border-right: 1px solid rgba(255, 255, 255, .6666);
	box-sizing: content-box;
	left: .5rem;
	margin: 0;
	padding: 0 .5rem 0 .125rem;
	position: absolute;
}

.intro-separator::before,
.intro-separator::after {
	background: #dee2e6;
	content: "";
	flex-grow: 1;
	height: 1px;
	position: relative;
	top: 1px;
}

.intro-separator::before {
	margin-right: .5rem;
}

.intro-separator::after {
	margin-left: .5rem;
}

.intro-separator {
	align-items: center;
	color: #6c757d;
	display: flex;
	line-height: 1;
	margin: 1.5rem 0;
}

.intro-hint {
	color: #6c757d;
}

.img-code {
	border-color: #ced4da;
	padding: 2.5px;
}

.form-group-label {
	position: relative;
}

.form-group-label + .form-group-label {
	margin-top: 1.25rem
}

.form-group-label label {
	align-items: center;
	border: thin solid transparent;
	color: #6c757d;
	display: flex;
	font-size: 1rem;
	left: 1rem;
	line-height: 1;
	margin: 1.25rem 0 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	transform: translateY(calc(1px - 50%));
	transform-origin: 0 0;
	transition: color .1s ease-in-out, transform .1s ease-in-out;
}

.form-group-label .form-control::placeholder {
	color: transparent;
}

.form-group-label .form-control:not(:placeholder-shown) ~ label::before {
	background: #fff;
	bottom: calc(50% - 2px);
	color: #6c757d;
	content: "";
	height: 50%;
	left: -.25rem;
	position: absolute;
	right: -.25rem;
	z-index: -1;
}

.form-group-label .form-control:not(:placeholder-shown) ~ label {
	transform: scale(.75) translateY(-200%);
}

.parsley-errors-list,
.inline_error_mark {
	color: #dc3545;
	font-size: .8125rem;
	margin-top: .5rem;
}

.desc-wrap-collapsed::before {
	background: linear-gradient(rgba(255, 255, 255, 0), #fff 75%);
	bottom: 0;
	content: "";
	height: 10rem;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 1
}

.desc-wrap-collapsed {
	height: 28rem;
	overflow: hidden;
	position: relative;
}

.desc-group {
	border: thin solid #e9ecef;
}

.desc-group:not(:last-child) {
	margin-bottom: 1rem;
}

.desc-list,
.desc-list .desc-details {
	margin-bottom: 0;
}

.desc-list:nth-child(2n+1) {
	background: #f1f3f5;
}

.desc-title {
	border-right: thin solid #e9ecef;
	color: #6c757d;
	font-weight: 400;
}

.desc-logo {
	position: absolute;
	right: .75rem;
	top: .125rem;
}

.btn-expand-wrap {
	align-items: center;
	display: flex;
}

.btn-expand-wrap-desc {
	margin-top: -1rem;
	position: relative;
	z-index: 1;
}

.compare-wrap {
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	scrollbar-color: rgba(0, 0, 0, .25) rgba(0, 0, 0, 0);
	scrollbar-width: thin;
}

.compare-wrap::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 6px;
}

.compare-header,
.compare-main,
.compare-column-wrap {
	background: #fff;
	display: flex;
}

.compare-main {
	display: inline-flex;
	min-width: 100%;
	overflow: hidden;
}

.compare-column-wrap {
	flex-grow: 1;
}

.compare-column {
	flex-basis: 100%;
	min-width: 10rem;
}

.compare-column-wrap .compare-column:nth-last-child(n+2),
.compare-column-wrap .compare-column:nth-last-child(n+2) ~ .compare-column {
	flex-basis: 50%;
}

.compare-column-wrap .compare-column:nth-last-child(n+3),
.compare-column-wrap .compare-column:nth-last-child(n+3) ~ .compare-column {
	flex-basis: calc(100% / 3);
}

.compare-column-wrap .compare-column:nth-last-child(n+4),
.compare-column-wrap .compare-column:nth-last-child(n+4) ~ .compare-column {
	flex-basis: 25%;
}

.compare-column-wrap .compare-column:nth-last-child(n+5),
.compare-column-wrap .compare-column:nth-last-child(n+5) ~ .compare-column {
	flex-basis: 20%;
}

.compare-column-header {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	position: relative;
}

.compare-column-header .prod-item-img-wrap {
	height: 8rem;
	margin-bottom: 1rem;
	transition: height .3s ease-in-out, width .3s ease-in-out;
	width: 8rem;
}

.compare-column-header .prod-item-info {
	max-height: 3rem;
	overflow: hidden;
	transition: max-height .3s ease-in-out;
}

.compare-header-marker {
	position: relative;
	top: -12rem;
}

.compare-header.is-sticky .compare-column-header .prod-item-img-wrap {
	height: 4rem;
	width: 4rem;
}

.compare-header.is-sticky .compare-column-header .prod-item-info {
	max-height: 0;
}

.compare-aside {
	max-width: 10rem;
	min-width: 10rem;
}

.compare-aside-header {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	padding: 1rem 0;
}

.compare-title {
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
	font-size: 1rem;
	font-weight: 400;
	height: 3rem;
	line-height: 1;
	margin: .5rem 0 0;
	padding: 1rem 0 1rem .75rem;
	white-space: nowrap;
}

.compare-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.compare-column-main .compare-list {
	margin: 3.5rem 0;
}

.compare-column-main .compare-list:last-child {
	margin-bottom: 0;
}

.compare-item {
	border-top: 1px solid #dee2e6;
	padding: .625rem .75rem;
	position: relative;
}

.compare-aside-header,
.compare-header .compare-column-wrap,
.compare-list {
	border-bottom: 1px solid #dee2e6;
}

.compare-header .prod-item-name {
	font-size: .8125rem;
}

.compare-column-main .compare-common::after {
	background: rgba(255, 255, 255, .5);
	bottom: 0;
	content: "";
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
}

.compare-main .compare-item,
.compare-aside-header,
.compare-column-header:not(:last-child) {
	border-right: thin solid #dee2e6;
}

.compare-aside-main:first-child .compare-item {
	border-left: thin solid #dee2e6;
}

.compare-aside-main .compare-item {
	background: #f8f9fa;
}

.img-empty {
	max-height: 20rem;
	width: 50vw;
}

.filter-icon {
	color: #6464f3;
	display: inline-block;
}

.filter-icon > .svg-icon {
	position: relative;
	top: -1px;
}

.img-screenshot {
	background: #f1f3f5;
	border-radius: .25rem;
	max-height: 50vh;
	max-height: calc(var(--vh, 1vh) * 50);
	object-fit: cover;
	object-position: top;
}

.feature-list {
	padding-top: 1px;
}

.feature-item {
	align-items: flex-start;
	border-color: #e9ecef;
	border-style: solid;
	border-width: 1px 0;
	display: flex;
	flex-basis: 50%;
	margin-top: -1px;
	max-width: 50%;
	padding-bottom: .5rem;
	padding-top: .5rem;
}

.feature-item:nth-child(2n+1) {
	padding-right: .5rem;
}

.feature-item:nth-child(2n) {
	padding-left: .5rem;
}

.feature-icon {
	color: #6464f3;
	flex-shrink: 0;
	margin-right: .5rem;
	margin-top: .25rem;
}

.content-prod-page .h2[id] {
	margin-top: -3.5rem;
	padding-top: 3.5rem;
}

.prod-page-sections-wrap.prod-page-sections-wrap {
	background: rgba(255, 255, 255, 0);
	transition: background-color .1s ease-in-out;
}

.prod-page-sections-wrap.is-sticky {
	background: #fff;
	box-shadow: 0 -1px #dee2e6 inset, 0 .5rem .75rem -.5rem rgba(73, 80, 87, .3);
}

.prod-page-sections {
	border-radius: 0;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
}

.prod-page-sections .prod-page-section {
	background: none;
	border: 0;
	color: #677078;
	margin: 0 !important;
	padding-left: .75rem;
	padding-right: .75rem;
	transition: color .15s ease-in-out;
	white-space: nowrap;
	width: auto;
}

.prod-page-sections .prod-page-section:hover {
	color: inherit;
}

.prod-page-sections .prod-page-section:active,
.prod-page-sections .prod-page-section.active {
	color: #5858dc;
}

.prod-page-section::after {
	background: #6464f3;
	bottom: 0;
	content: "";
	height: .1875rem;
	left: 0;
	position: absolute;
	right: 0;
	transform-origin: 100% 100%;
	transform: scaleY(0);
	transition: transform .15s ease-in-out;
}

.prod-page-section.active::after {
	transform: scaleY(1);
}

.prod-page-section {
	position: relative;
}

.offer-row,
.offer-row-hint {
	border-top: thin solid #ececec;
}

.offer-row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -.75rem;
	padding: 1.25rem .75rem;
	position: relative;
}

:not(.offer-row-block) > .offer-row:first-child {
	border-top: 0;
	padding-top: 0;
}

:not(.offer-row-block) > .offer-row:last-child {
	padding-bottom: 0;
}

@keyframes pulsate {
	50% {
		opacity: 1;
	}
}

.offer-row-block::before {
	animation: 1s .15s both ease-in-out pulsate;
	background: rgba(100, 100, 250, .05);
	bottom: 0;
	content: "";
	left: 0;
	margin: 0 -.75rem;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
}

.offer-row-block {
	position: relative;
}

.offer-row-block.collapse:not(.show) {
	display: none !important;
}

.offer-site-wrap {
	display: flex;
	flex-direction: column;
	padding: .125rem;
}

.offer-site-img-wrap::before {
	padding-top: 25%;
}

.offer-site-img-wrap {
	width: 7.5rem;
}

.offer-site-img-wrap .offer-site-text {
	border: 1px solid #dee2e6;
	color: #6c757d;
	font-size: .75rem;
	line-height: 1.75rem;
	padding: 0 .375rem;
	text-align: center;
}

.offer-col-site {
	width: 8.75rem;
}

.offer-rating {
	display: block;
	margin: .375rem 0 calc(-1.25rem);
	width: min-content;
}

.offer-col-name {
	flex-basis: 100%;
	margin-bottom: 1.25rem;
	min-width: 0;
	order: -1;
}

.offer-col-name a {
	text-decoration: none;
}

.offer-col-name .offer-name {
	font-size: .9375rem;
}

.offer-availability,
.offer-shipping {
	color: #6c757d;
	font-size: .8125rem;
}

.offer-variants {
	background-position-x: right .375rem;
	color: #6c757d;
	display: none;
	font-size: .8125rem;
	margin: 0 -.5rem -1rem;
	min-height: 0;
	padding: .5rem;
	transition: color .15s ease-in-out;
}

.offer-variants:hover {
	color: #495057;
}

.offer-variants > .svg-icon {
	transition: transform .15s ease-in-out;
}

.offer-variants.open > .svg-icon {
	transform: rotate(180deg);
}

.offer-availability .bullet {
	margin-right: .25rem;
}

.offer-variants .bullet {
	margin-left: .25rem;
}

.offer-row-multi .offer-variants {
	align-items: center;
	display: flex;
}

.offer-col-price {
	margin-left: auto;
	padding-left: .5rem;
	text-align: right;
}

.offer-col-name .offer-availability {
	display: none;
}

.offer-col-price .offer-availability {
	display: block;
}

.offer-price {
	font-size: 1rem;
	font-weight: 500;
}

.offer-col-btn {
	margin-top: 1.25rem;
	width: 100%;
}

.offer-row .offer-btn::before {
	bottom: 0;
	content: "";
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.offer-col-btn .offer-btn:hover {
	background-color: #6464f3;
	border-color: #6464f3;
}

.offer-row a:not(.offer-btn),
.offer-row button:not(.offer-btn) {
	position: relative;
	z-index: 1;
}

.btn-country-select + .dropdown-menu {
	border-radius: 0;
	columns: 2;
	margin-bottom: .5rem;
	margin-left: -5px;
	padding: .5rem;
	width: 100vw;
	z-index: 1030;
}

.btn-country-select + .dropdown-menu > .dropdown-item {
	border-radius: 1rem;
	margin-left: -.25rem;
	padding: .5rem 1rem .5rem 1.25rem;
	width: min-content;
}

.btn-country-select + .dropdown-menu > .dropdown-item:last-child {
	margin-bottom: 0;
}

.btn-country-select + .dropdown-menu img {
	height: 1rem;
	width: 1rem;
}

@media (min-width: 512px) {
	.intro-content {
		margin: 0 auto;
		padding: 2rem;
		width: 32rem;
	}
	.intro-card.card {
		border-radius: 1rem;
	}
	.intro-card-body.card-body {
		padding: 2rem 2.25rem;
	}
	.intro-logo-link {
		padding: 1rem;
		margin-top: -2rem;
	}
	.strip-wrap-extended .strip-item {
		flex-basis: auto;
		max-width: 100%;
	}
	.btn-country-select + .dropdown-menu {
		border-radius: .25rem;
		columns: 3;
		max-width: none;
		padding: 1rem;
		width: auto;
	}
}

@media (min-width: 576px) {
	.prod-item-shipping {
		letter-spacing: 0;
	}
	.prod-card-grid {
		grid-template-columns: repeat(3, calc(100% / 3));
	}
	.shm-box-wrap {
		grid-gap: 1.5rem;
		grid-template-columns: repeat(auto-fill, minmax(10.5rem, 1fr));
	}
	.map-sublist {
		margin-left: 2rem;
	}
	.map-icon {
		margin-left: 0;
		margin-right: .75rem;
		order: 0;
	}
	.brands-title {
		font-size: 1.125rem;
		margin-bottom: 1.5rem;
	}
	.brands-list + .brands-title {
		margin-top: 5rem;
	}
	.brands-list {
		display: grid;
		grid-gap: 3rem;
		grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
	}
	.index-cat-item,
	.card-index .card-index-text.prod-item,
	.swiper-container-prods .prod-list .prod-item {
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
	}
	.card-index .swiper-container-prods .prod-list .prod-item {
		flex-basis: 50%;
		max-width: 50%;
	}
	.index-cat-name {
		font-size: 1.125rem;
	}
	.desc-wrap-collapsed {
		height: 38rem;
	}
	.desc-group {
		border-width: thin 0;
	}
	.desc-group:not(:last-child) {
		margin-bottom: 2rem;
	}
	.desc-logo {
		right: 1.5rem;
		top: .75rem;
	}
	.img-screenshot {
		max-height: none;
	}
	.prod-page-wrap {
		grid-template-areas: "prod-page-slider prod-page-head" "prod-page-slider prod-page-info";
		grid-template-columns: 35% 65%;
		grid-template-rows: auto 1fr;
	}
	.prod-page-title {
		font-size: 1.125rem;
		margin: 0;
		width: 100%;
	}
	.prod-page-slider {
		align-items: flex-start;
		display: flex;
		justify-content: center;
		padding: 1rem 2rem 1rem 1rem;
	}
	.prod-page-options {
		align-self: flex-start;
		flex-direction: row;
		margin-left: auto;
		margin-right: -.5rem;
		margin-top: -.5rem;
		order: -1;
		padding: 0 0 .5rem .5rem;
		position: relative;
	}
	.prod-card-list .prod-item-options .prod-option {
		padding: .5rem;
	}
	.btn-expand-wrap::before,
	.btn-expand-wrap::after {
		background: #e9ecef;
		content: "";
		height: 1px;
		width: 25%;
	}
}

@media (min-width: 768px) {
	.modal-menu .modal-dialog {
		max-width: 22rem;
	}
	.cat-menu-list-primary {
		padding-top: .5rem;
	}
	.cat-menu-item {
		border-bottom: 0;
	}
	.cat-menu-link.cat-menu-link {
		padding-bottom: .5rem;
		padding-top: .5rem;
	}
	.prod-card-grid {
		grid-template-columns: repeat(4, 25%);
	}
	.prod-card-list .prod-item-img-wrap {
		flex-basis: 10.5rem;
		min-width: 10.5rem;
	}
	.shm-box-wrap {
		grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
	}
	.map-list {
		margin-bottom: -1rem;
	}
	.map-list-cols {
		columns: 3;
	}
	.map-list > .map-item,
	.map-list-cols > .map-item {
		margin-bottom: 1.25rem;
	}
	.map-list > .map-item {
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
	}
	.map-list > .map-item.map-item {
		padding: 0;
	}
	.map-link {
		display: inline-flex;
		padding: .125rem 0;
	}
	.map-item .map-sublist .map-item .map-link {
		border-width: 0;
	}
	.map-sublist .map-link-more {
		line-height: 1.5;
		padding: 0;
	}
	.footer-title {
		padding: .5rem 0;
	}
	.footer .nav-item > .nav-link {
		color: rgba(255, 255, 255, .6);
		margin: 0;
		padding: .25rem 0;
	}
	.footer .nav-item > .nav-link:hover {
		color: rgba(255, 255, 255, .8);
	}
	.footer-bottom {
		border-top: thin solid rgba(255, 255, 255, .15);
	}
	.alert-cookies {
		align-items: center;
		flex-direction: row;
		line-height: 1.5;
		padding: 1.5rem;
	}
	.alert-cookies-content {
		margin: 0 1rem 0 0;
		-webkit-mask-image: none;
		max-height: none;
		overflow: visible;
	}
	.alert-cookies-btn {
		align-self: center;
		margin-left: auto;
		min-width: 14rem;
	}
	.index-cat-item,
	.card-index .card-index-text.prod-item,
	.swiper-container-prods .prod-list .prod-item {
		flex-basis: 25%;
		max-width: 25%;
	}
	.card-index .swiper-container-prods .prod-list .prod-item {
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
	}
	.index-cat-name {
		font-size: 1.25rem;
		letter-spacing: -.25px;
		margin-bottom: 1.125rem;
	}
	.banner-icon {
		height: 4rem;
		width: 4rem;
	}
	.banner-title {
		font-size: 1.25rem;
	}
	.btn-expand-wrap::before,
	.btn-expand-wrap::after {
		width: 50%;
	}
	.offer-row {
		flex-wrap: nowrap;
		margin: 0;
		padding: 1.5rem 0;
	}
	.offer-availability,
	.offer-shipping {
		margin-top: .375rem;
		line-height: 1rem;
		white-space: nowrap;
	}
	.offer-col {
		padding: 0 1rem;
	}
	.offer-col:first-child {
		padding-left: 0;
	}
	.offer-col:last-child {
		padding-right: 0;
	}
	.offer-col-name {
		margin-bottom: 0;
		order: 0;
	}
	.offer-site-wrap {
		align-items: center;
	}
	.offer-name,
	.offer-price {
		line-height: 1.25rem;
	}
	.offer-col-name .offer-availability {
		display: block;
	}
	.offer-col-price .offer-availability {
		display: none;
	}
	.offer-price {
		font-size: 1.0625rem;
	}
	.offer-col-btn {
		margin-top: 0;
		width: auto;
	}
	.offer-btn {
		margin: .125rem 0;
		padding: .5rem 2rem;
	}
	.offer-row-block::before {
		margin: -.75rem;
	}
	.offer-row-block .offer-row {
		border: 0;
		padding: .75rem 0;
	}
	.offer-row-block .offer-row:first-child {
		padding-top: 0;
	}
	.offer-row-block .offer-row:last-child {
		margin-bottom: .75rem;
	}
	.offer-row-block {
		margin-left: 8.75rem;
	}
	.offer-row-block .offer-col-site {
		display: none;
	}
	.strip-wrap-collapsed::before {
		background: linear-gradient(rgba(255, 255, 255, 0) 50%, rgb(255, 255, 255, 1));
		bottom: 0;
		content: "";
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
	}
	.strip-link-img > .strip-img {
		border-radius: 1rem;
		height: 4.5rem;
		padding: .125rem;
		width: 4.5rem;
	}
}

@media (min-width: 992px) {
	.h4 {
		font-size: 1.5rem;
	}
	.card-index-title {
		font-size: 1.375rem;
	}
	.shm-outer-header,
	.breadcrumb {
		background: none;
		margin: -1.5rem 0 0;
	}
	.shm-outer-header {
		flex-wrap: nowrap;
	}
	.shm-compare-container {
		padding: .5rem 0;
	}
	.breadcrumb {
		flex-wrap: wrap;
		overflow-x: visible;
	}
	.shm-breadcrumb {
		flex-wrap: nowrap;
		overflow: hidden;
		padding: .5rem 0;
		width: auto;
	}
	.shm-outer-header .shm-breadcrumb {
		margin-top: 0;
	}
	.shm-breadcrumb > .breadcrumb-item:last-child {
		overflow: hidden;
	}
	.shm-breadcrumb > .breadcrumb-item:last-child > span {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.container,
	.container-fluid {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.col-side:not(:empty) {
		padding-right: 1rem;
		width: 17rem;
	}
	.col-main + .col-side:not(:empty) {
		padding-left: 1rem;
		padding-right: 0;
	}
	.col-side > * {
		width: 100%;
	}
	.col-main:not(:only-child) {
		max-width: calc(100% - 17rem);
		overflow-x: auto;
	}
	.col-side.col-empty,
	.shm-outer-header:empty {
		display: none !important;
	}
	.col-side:empty + .col-main,
	.col-side.col-empty + .col-main {
		max-width: none;
	}
	.header-top-container {
		flex-wrap: nowrap;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.header-logo-img {
		height: 3rem;
		margin: -.25rem 0;
		width: 7.75rem;
	}
	.header-btn.header-btn-menu {
		margin-left: .75rem;
		margin-right: 0;
		order: 1;
	}
	.header-section-search {
		margin: 0 1rem;
		order: 0;
	}
	.header-input-search:not(:focus) ~ .header-btn-clear {
		opacity: 0;
		visibility: hidden;
	}
	.header-bottom,
	.header-menu,
	.header-menu-list {
		display: flex;
	}
	.header-menu {
		width: 100%;
	}
	.header-menu-list {
		margin-left: -1rem;
	}
	.header-menu-list {
		margin-right: auto;
	}
	.main {
		margin: 1.5rem auto;
	}
	.shm-card {
		border-radius: .25rem;
	}
	.shm-card + .shm-card {
		border-top: 0;
		margin-top: 1.5rem;
	}
	.shm-card-body {
		padding: 1.5rem;
	}
	.mobile-padding {
		padding-left: .5rem;
		padding-right: .5rem;
	}
	.brand-social-wrap {
		padding: 1rem;
	}
	.cat-desc {
		margin-top: 1.5rem;
	}
	.prod-list-wrap {
		margin-left: -1.5rem;
		margin-right: -1.5rem;
	}
	.prod-list-index {
		border: 0;
		margin: 0;
	}
	.prod-list-index > .prod-item {
		min-width: 20%;
	}
	.prod-card-grid {
		grid-template-columns: repeat(3, calc(100% / 3));
	}
	.prod-item {
		padding: 1.5rem;
	}
	.prod-item-content-wrap {
		flex-direction: row;
		flex-grow: 1;
	}
	.prod-card-list .prod-item-name {
		font-size: 1.125rem;
	}
	.prod-card-list .prod-item-options {
		bottom: auto;
		margin: .5rem 1rem;
		top: 0;
	}
	.prod-card-list .prod-desc {
		margin-bottom: 0;
		max-width: 85%;
	}
	.prod-card-list .prod-item-content,
	.prod-card-list .prod-item-content-extra {
		justify-content: center;
		margin: 0 1.25rem;
	}
	.prod-card-list .prod-item-content {
		flex-basis: 100%;
	}
	.prod-card-list .prod-item-content-extra {
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;
		margin: 0;
		text-align: right;
	}
	.prod-item-shipping {
		white-space: nowrap;
	}
	.prod-card-grid .prod-item-shipping {
		white-space: normal;
	}
	.prod-card-list .prod-interval {
		text-align: right;
	}
	.prod-card-list .prod-item-merchant-link {
		margin-right: 0;
	}
	.shm-box-wrap {
		grid-gap: 1.5rem;
		grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
	}
	.shm-box-title {
		font-size: 1.125rem;
	}
	.strip-wrap-spaced::before,
	.strip-wrap-spaced::after {
		width: 1.5rem;
	}
	.strip-wrap-spaced {
		margin-bottom: .5rem;
		margin-left: -1.5rem;
		margin-top: 1.5rem;
		width: calc(100% + 3rem);
	}
	.strip-wrap-spaced .strip-item {
		padding-left: 1.5rem;
	}
	.strip-wrap-spaced .swiper-button {
		top: calc(50% - .75rem);
	}
	.swiper-button {
		display: flex;
	}
	.strip-link-cat > .strip-img {
		height: 8.5rem;
		padding: 0;
		width: 8.5rem;
	}
	.strip-link-cat-text {
		font-size: 1rem;
	}
	.map-list-cols {
		columns: 4;
	}
	.map-list > .map-item {
		flex-basis: 25%;
		max-width: 25%;
	}
	.alphabet-list {
		background: none;
		padding-top: 0;
	}
	.card-index .card-index-text.prod-item,
	.swiper-container-prods .prod-list .prod-item {
		flex-basis: 20%;
		max-width: 20%;
	}
	.card-index .swiper-container-prods .prod-list .prod-item {
		flex-basis: 25%;
		max-width: 25%;
	}
	.index-cat-item {
		flex-basis: calc((100% - 4rem) / 5);
		max-width: calc((100% - 4rem) / 5);
		margin-bottom: 1.5rem;
	}
	.index-cat-item:not(:last-child) {
		margin-right: 1rem;
	}
	.index-cat-img-wrap {
		border-radius: .25rem
	}
	.compare-wrap,
	.compare-header {
		overflow: visible;
	}
	.compare-header {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1020;
	}
	.compare-aside {
		max-width: 16rem;
		min-width: 16rem;
	}
	.compare-aside-main:first-child .compare-item {
		border-left: 0;
	}
	.compare-aside-main .compare-item {
		border-right: thin solid #e9ecef;
	}
	.compare-column-main .compare-list {
		margin: 4rem 0;
	}
	.compare-column-main:last-child .compare-item {
		border-right: 0;
	}
	.compare-title {
		margin-top: 1rem;
		padding-left: 1rem;
	}
	.compare-item::before {
		content: "";
		position: absolute;
		left: -100vw;
		top: 0;
		right: -100vw;
		bottom: 0;
		mix-blend-mode: multiply;
		pointer-events: none;
	}
	.compare-column-main .compare-item::before {
		z-index: 1;
	}
	.compare-aside-main .compare-item:hover::before,
	.compare-column-main .compare-item:hover::before {
		background: #f8f9fa;
	}
	.compare-item {
		padding: .875rem 1rem;
	}
	.feature-list .feature-item {
		border-color: #f1f3f5;
		flex-basis: calc(100% / 3);
		max-width: calc(100% / 3);
		padding: .5rem 0;
	}
	.feature-text {
		font-size: .9375rem;
	}
	.prod-page-head {
		flex-wrap: nowrap;
	}
	.prod-page-title {
		font-size: 1.375rem;
		width: auto;
	}
	.prod-page-options {
		margin-top: -.25rem;
		order: 0;
	}
	.prod-page-sections .prod-page-section {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	.prod-page-section::after {
		margin: 0 .75rem;
	}
	.btn-expand-wrap::before,
	.btn-expand-wrap::after {
		width: 100%;
	}
	.about-title {
		font-size: 1.5rem;
	}
	.about-text {
		font-size: 1.125rem;
	}
	.btn-filter-wrap {
		flex-wrap: wrap;
		margin-bottom: 0;
		overflow-x: visible;
		width: auto;
	}
}

@media (min-width: 1280px) {
	.prod-card-grid {
		grid-template-columns: repeat(4, 25%);
	}
}

@-moz-document url-prefix() {
	select {
		text-indent: -2px;
	}
}

.no-selection {
	-ms-user-select: none; /* IE10+ */
	-moz-user-select: none; /* mozilla browsers */
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	user-select: none; /* standard syntax */
}

/* BEGIN BLOG MARKDOWN ************************************************************************************************/
/* table */
.container-info table {
	background: #fff;
	border: thin solid rgba(0, 0, 0, .125);
	border-collapse: separate;
	color: #212529;
	margin-bottom: 1.5rem;
	width: 100%;
}

.container-info table th,
.container-info table td {
	font-size: .875rem;
	padding: .375rem .5rem;
}

.container-info table th {
	font-weight: 500;
}

.container-info table td:first-child {
	background: #f1f3f5;
	white-space: nowrap;
}

.container-info table tr:nth-child(2n+1) td:nth-child(n+2) {
	background: #eaeafb;
}

.container-info blockquote {
	margin-left: 2rem;
}
/* content */
.container-info a:empty {
	vertical-align: middle;
	/*display: block;*/
}

.container-info a[href] {
	border-bottom: thin dotted;
	color: inherit;
	text-decoration: none;
	overflow-wrap: break-word;
}

.container-info a[href]:hover {
	border-bottom: thin solid #6c757d;
	color: inherit;
}

.container-info a.feedback-contact {
	border-bottom-width: 0;
	color: #5858dc;
}

.container-info a.feedback-contact:hover,
.container-info a.feedback-contact:focus {
	border-bottom-width: thin;
}

.container-info .layer-anchor {
	border-bottom: 0;
}

.container-info h1,
.container-info h2,
.container-info h3,
.container-info h4 {
	color: #343a40;
	// font-display: optional;
	font-family: 'Roboto Slab', serif;
	margin-bottom: .75em;
}

.container-info h1 {
	font-size: 1.25rem;
}

.container-info h2 {
	border-top: thin solid rgba(0, 0, 0, .075);
	font-size: 1.125rem;
	margin-top: 1.5rem;
	padding-top: 1.5rem;
}

.container-info h2:first-child {
	border-top: 0;
	margin-top: 0;
	padding-top: 0;
}

.container-info h3 {
	font-size: 1.0625rem;
}

.container-info h4 {
	font-size: 1rem;
}

.container-info h2 > a[href] {
	border: 0;
	margin-left: .25rem;
}

.container-info h1:not(:hover) > a,
.container-info h2:not(:hover) > a,
.container-info h3:not(:hover) > a {
	visibility: hidden;
}

.container-info h1 > a::after,
.container-info h2 > a::after,
.container-info h3 > a::after {
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3e%3cpath fill='none' stroke='%235858dc' stroke-width='2' d='M14 11a5 5 0 00-7.54-.54l-3 3a5 5 0 007.07 7.07l1.71-1.71M10 13a5 5 0 007.54.54l3-3a5 5 0 00-7.07-7.07l-1.72 1.71'/%3e%3c/svg%3e") 50% 50% / 100% 100% no-repeat;
	content: "";
	display: inline-block;
	height: 1rem;
	width: 1rem;
}

.container-info ul,
.container-info ol {
	padding-left: 1rem;
}

.container-info ul:not(.table-of-contents) > li > p:first-child::after,
.container-info ol:not(.table-of-contents) > li > p:first-child::after {
	content: "";
	display: block;
	height: 1rem;
}

.container-info ul:not(.table-of-contents) > li > p:first-child,
.container-info ol:not(.table-of-contents) > li > p:first-child {
	display: inline;
}

.container-info ul + p {
	margin-top: 1rem;
}

.container-info p + ul {
	margin-top: -.5rem;
}

.container-info .table-of-contents {
	font-style: normal;
	margin-bottom: 2rem;
	margin-top: 2rem;
}

.container-info .table-of-contents li {
	margin: .5rem 0;
}

.container-info .bigger {
	color: #5858dc;
	font-size: 1.125rem;
}

.container-info img {
	height: auto;
	max-width: 100%;
}

.container-info ol ol,
.container-info ol ul,
.container-info ul ol,
.container-info ul ul {
	margin-bottom:1rem;
	padding-left: 1.5rem;
}

.container-info .img-thumbnail {
	border: 0;
	box-shadow: 0 0 .75rem rgba(0, 0, 0, .15);
	margin-bottom: .5rem;
	padding: 0;
}

.container-info .d-flex > img + img {
	margin-left: 1rem;
}

.container-info .d-flex > img:nth-last-child(n+2),
.container-info .d-flex > img:nth-last-child(n+2) ~ img {
	max-width: calc((100% - 1rem) / 2);
}

.container-info .d-flex > img:nth-last-child(n+3),
.container-info .d-flex > img:nth-last-child(n+3) ~ img {
	max-width: calc((100% - 2rem) / 3);
}

.container-info .d-flex > img:nth-last-child(n+4),
.container-info .d-flex > img:nth-last-child(n+4) ~ img {
	max-width: calc((100% - 3rem) / 4);
}

.container-info .d-flex > img:nth-last-child(n+5),
.container-info .d-flex > img:nth-last-child(n+5) ~ img {
	max-width: calc((100% - 4rem) / 5);
}

.container-info .img-centred {
	display: block;
	margin: 0 auto;
}

.container-info p {
	margin-top: 1rem;
}

.container-info p:first-child {
	margin-top: 0;
}

.container-info p:empty {
	display: none;
}

.container-info table td > code:only-child {
	margin-right: 1rem;
	white-space: nowrap;
}

.container-info code {
	background-color: #f4f8fa;
	border-radius: .2rem;
	color: #5b6d7f;
	padding: .2rem .4rem;
	white-space: pre-wrap;
}

.container-info code[class~="language"] {
	display: block;
}

.container-info pre code {
	background: #f8f9fa;
	border: thin solid rgba(0, 0, 0, .125);
	border-radius: .25rem;
	color: #495057;
	display: block;
	font-size: .8125rem;
	padding: 1.5rem 1.25rem;
	word-break: break-word;
}
/*
.container-info .tip + p::before,
.container-info .note + p::before {
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	margin-right: .5rem;
	margin-top: -.25rem;
	vertical-align: middle;
}

.container-info .tip + p::before {
	background-image: url("/img/mpro_intro/tip.svg");
	height: 1.25rem;
	width: .875rem;
}

.container-info .note + p::before {
	background-image: url("/img/mpro_intro/note.svg");
	height: 1.125rem;
	width: .875rem;
}
*/
.container-info .tip + p,
.container-info .note + p {
	border-style: solid;
	border-width: 0 0 0 .25rem;
	padding: 1rem;
}

.container-info .tip + p > strong,
.container-info .note + p > strong {
	display: inline-block;
	margin-bottom: .25rem;
}

.container-info .tip + p {
	background: #f0f0f5;
	border-color: #635090;
}

.container-info .tip + p > strong {
	color: #635090;
}

.container-info .note + p {
	background: #fff9db;
	border-color: #e09d3e;
}

.container-info .note + p > strong {
	color: #e09d3e;
}

.container-info details {
	margin-bottom: 1rem;
}

.container-info summary {
	border-bottom: thin dotted #6868ec;
	color: #6868ec;
	display: inline-block;
	line-height: 1.25;
	list-style: none;
}

.container-info summary::-webkit-details-marker {
	display: none;
}

.container-info details[open] > summary {
	border: 0;
	margin-bottom: .5rem;
}

@media (min-width: 992px) {
	.container-info ul,
	.container-info ol {
		padding-left: 2.5rem;
	}
	.container-info .card-body > ol {
		padding-left: 1rem;
	}
}

/* END BLOG MARKDOWN **************************************************************************************************/

/* BEGING REVIEWS *****************************************************************************************************/

/* Summary */

.reviews-summary .progress {
	height: 14px;
	border-radius: 7px;
}

.reviews-summary .progress-bar {
	background: #fcc419;
}

.reviews-summary .progress-bar-5 {
	background: #70be1a;
}

.reviews-summary .progress-bar-4 {
	background: #b0cb1e;
}

.reviews-summary .progress-bar-3 {
	background: #fbae06;
}

.reviews-summary .progress-bar-2 {
	background: #fbae06;
}

.reviews-summary .progress-bar-1 {
	background: #db7b25;
}

.reviews-summary .progress-percent {
	margin-left: .5rem;
	width: 5ch;
}

/* Reviews */

.reviews-list:first-child .reviews-row:first-child {
	margin-top: 0;
}

.reviews-info-wrap {
	display: flex;
	margin-bottom: .75rem;
}

.reviews-info {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
}

.reviews-avatar {
	background: #e9ecef;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	height: 4rem;
	margin-right: 1.5rem;
	position: relative;
	width: 4rem;
}

.reviews-acronym {
	font-size: 2rem;
	font-weight: 500;
	margin: auto;
	text-transform: uppercase;
}

.reviews-badge {
	border-radius: 50%;
	bottom: 0;
	height: 1rem;
	position: absolute;
	right: 0;
	width: 1rem;
}

.reviews-avatar-mobile {
	background: #e9ecef;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	height: 3rem;
	margin-right: 1rem;
	position: relative;
	width: 3rem;
}

.reviews-avatar-mobile .reviews-acronym {
	font-size: 1.5rem;
}

.reviews-avatar-mobile .reviews-badge {
	height: .75rem;
	width: .75rem;
}

.reviews-badge-verified {
	background: #59ad00;
	color: #fff;
}

.reviews-badge-icon {
	transform: scale(.75);
}

.reviews-details {
	min-width: 0;
}

.reviews-avatar-rating-user-wrap {
	display: flex;
}

.reviews-rating-user-wrap {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.reviews-user-wrap {
	align-items: baseline;
	display: flex;
	flex-wrap: nowrap;
	margin-bottom: .5rem;
}

.reviews-user {
	margin-right: .75rem;
	font-weight: 400;
}

.reviews-admin,
.reviews-verified {
	margin-right: .75rem;
}

.reviews-admin {
	color: #007bff;
}

.reviews-verified {
	color: #59ad00;
}

.reviews-reply {
	color: #6c757d;
}

.reviews-rating-wrap {
	display: flex;
	align-items: center;
	margin-bottom: .5rem;
}

@media (max-width: 991.98px) {
	.reviews-rating-wrap {
		flex-direction: column;
		align-items: flex-start;
	}
	.reviews-rating-wrap .rating {
		width: 100%;
	}
	.content-header > .h1 {
		background: #fff;
		margin: 0;
		padding: .75rem .75rem 0;
	}
}

.reviews-verified,
.reviews-reply-text {
	display: none;
}

.reviews-vote {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.reviews-vote-count {
	color: #adb5bd;
	vertical-align: middle;
}

.votes-up,
.votes-up-hover:hover {
	color: #67ae3e;
}

.votes-down,
.votes-down-hover:hover {
	color: #e71226;
}

.reviews-date {
	color: #adb5bd;
}

.reviews-title {
	font-weight: 600;
}

.reviews-text {
	margin-bottom: .5rem;
	font-weight: 400;
}

.reviews-replies .reviews-row,
.reviews-list .reviews-list {
	border-left: .2rem solid #ced4da;
}

/* review thumbs*/

.reviews-thumb-wrap {
	display: grid;
	grid-gap: .5rem;
	grid-template-columns: repeat(5, 1fr);
	margin-top: .5rem;
	max-width: 34rem;
}

.reviews-thumb-img {
	height: 100%;
	object-fit: cover;
	width: 100%;
	border: 1px solid #dee2e6!important;
}

.reviews-thumb-more {
	align-items: center;
	background: rgba(0, 0, 0, .5);
	color: #fff;
	display: none;
	font-family: sans-serif;
	font-size: 1.5rem;
	justify-content: center;
	pointer-events: none;
}

@media (max-width: 767.98px) {
	.reviews-thumb-wrap {
		grid-template-columns: repeat(3, 1fr);
		max-width: 22rem;
	}
	.reviews-thumb-link:nth-last-child(n+1):nth-child(1),
	.reviews-thumb-link:nth-last-child(n+3):nth-child(1) {
		grid-column: span 2;
		grid-row: span 2;
	}
	.reviews-thumb-link:nth-child(3) ~ .reviews-thumb-link {
		display: none;
	}
	.reviews-thumb-link:nth-child(3),
	.reviews-thumb-more {
		grid-column: 3;
		grid-row: 2;
	}
	.reviews-thumb-more {
		display: flex;
	}
}

/*********/


.reviews-list .reviews-list {
	border-top: 0;
	margin-top: 0;
	padding-left: 1rem;
	padding-top: 0;
}

.reviews-list .reviews-row {
	border-top: 1px solid #ececec;
	margin-top: 2rem;
	padding-top: 2rem;
}

.reviews-list .reviews-list .reviews-row {
	border-top: 0;
	margin-top: 1rem;
	padding-top: 0;
}

.reviews-list .reviews-replies {
	font-size: .9375rem;
	margin-top: 1rem;
}

.reviews-list .reviews-user-replied {
	font-size: 1.125rem;
}

.reviews-list .reviews-rating {
	display: inline-block;
	margin-right: .5rem;
	vertical-align: bottom;
}

@media (min-width: 576px) {
	.reviews-list .reviews-verified,
	.reviews-list .reviews-reply-text {
		display: inline;
	}
}

/* END REVIEWS ********************************************************************************************************/

/* ***** Compare mini list ***** */

.c-box {
	background-color: #e8e8ff;
	border: thin dashed #b1b1fc;
	height: 2rem;
	margin-right: .25rem;
	position: relative;
	width: 2rem;
}

.c-item {
	box-shadow: 0 0 0 1px #b1b1fc;
}

.c-item-remove {
	border: 0;
	height: 1rem;
	margin: -.25rem;
	min-height: 0;
	padding: 0;
	position: absolute;
	right: -1px;
	top: -1px;
	transition: opacity .15s ease-in-out;
	width: 1rem;
	z-index: 1;
}

@media (min-width: 992px) {
	.c-item-remove {
		opacity: 0;
	}
	.c-item:hover .c-item-remove {
		opacity: 1;
	}
}

.c-link {
	display: block;
}

.btn-compare-wrap {
	display: inline-flex;
}

.btn-compare {
	font-size: .75rem;
	margin-left: .25rem;
	min-height: 0;
	padding: .25rem .75rem;
}

.btn-clear-compare-list {
	height: 2rem;
	padding: 0;
	width: 2rem;
}

@media print {
	a.link-dotted,
	.has-link-dotted a {
		text-decoration: none;
	}
}

/* ***************************** */

/* Make sure hidden stays hidden - set specificity to the very last rule */
[hidden],
.hide {
	display: none !important;
}


/* Validation remake */

.was-validated {

	:invalid ~ .invalid-feedback,
	:invalid ~ .invalid-tooltip,
	.is-invalid ~ .invalid-feedback,
	.is-invalid ~ .invalid-tooltip {
		display: block;
	}

	.form-control:invalid, .form-control.is-invalid {
		border-color: #dc3545;
		padding-right: calc(1.5em + 0.75rem);
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
		background-position: right calc(0.375em + 0.1875rem) center;
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}

	.form-control:invalid:focus, .form-control.is-invalid:focus {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}

	textarea.form-control:invalid, textarea.form-control.is-invalid {
		padding-right: calc(1.5em + 0.75rem);
		background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
	}

	.custom-select:invalid, .custom-select.is-invalid {
		border-color: #dc3545;
		padding-right: calc(0.75em + 2.3125rem);
		background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
	}

	.custom-select:invalid:focus, .custom-select.is-invalid:focus {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}

	.form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
		color: #dc3545;
	}

	.form-check-input:invalid ~ .invalid-feedback,
	.form-check-input:invalid ~ .invalid-tooltip,
	.form-check-input.is-invalid ~ .invalid-feedback,
	.form-check-input.is-invalid ~ .invalid-tooltip {
		display: block;
	}

	.custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
		color: #dc3545;
	}

	.custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
		border-color: #dc3545;
	}

	.custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
		border-color: #e4606d;
		background-color: #e4606d;
	}

	.custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}

	.custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
		border-color: #dc3545;
	}

	.custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
		border-color: #dc3545;
	}

	.custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
		border-color: #dc3545;
		box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
	}
}

/* Custom rewrites */

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	padding: 1rem 1rem;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: .25em .25em;
	color: #000;
	background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: .25rem;
	opacity: .5;
}