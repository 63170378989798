/* Global styles */
::-webkit-scrollbar {
	width: 12px;
	height: 12px;
	background-color: rgba(0,0,0,0.03);
	/* background: linear-gradient(90deg, silver, white); */
	-webkit-border-radius: 0;
}

/* UI elements scrollbars */
body ::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar:horizontal {
	/* background: linear-gradient(0deg, white, silver); */
}
::-webkit-scrollbar-thumb:vertical {
	background: rgba(0,0,0,0.2);
	background-clip: padding-box;
	border: 1.5px solid rgba(0, 0, 0, 0);
	min-height: 30px;
	-webkit-border-radius: 0;
}
::-webkit-scrollbar-thumb:vertical:active {
	background: rgba(0,0,0,0.4);
	-webkit-border-radius: 0;
}
::-webkit-scrollbar-thumb:horizontal {
	background: rgba(0,0,0,0.2);
	-webkit-border-radius: 0;
	background-clip: padding-box;
	border: 1.4px solid rgba(0, 0, 0, 0);
	min-width: 30px;
}
::-webkit-scrollbar-thumb:horizontal:active {
	background: rgba(0,0,0,0.4);
	-webkit-border-radius: 0;
}
::-webkit-scrollbar:hover {
	background-color: rgba(0, 0, 0, 0.09);
}