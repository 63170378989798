// Variables
@import "1-variables/site";

// Import fonts
@import "1-variables/site-fonts";
@import "site-modules/clean-scrollbar";

// Vendor files
@import "5-vendor/bootstrap4/bootstrap.css";
@import "5-vendor/fancybox/core";

// Site plugins
@import "site-modules/plugins/shm.autocomplete";
@import "site-modules/plugins/shm.pwdmeter";
@import "site-modules/plugins/star-rating-svg";
@import "swiper/swiper-bundle.css";
@import "toastr";
@import "nouislider/dist/nouislider.css";

// Site modules
@import "site-modules/anim";
@import "site-modules/all";