#pm-cont { width: 260px; }
.pm-right { margin-left: 1em; float: left; }
.pm-left { margin-right: 1em; float: left; }
.pm-top { margin-bottom: 0.5em; }
.pm-bottom { margin-top: 0.5em; }
.pm-fix { clear: both; height: 0; line-height: 0; font-size: 0; }
#pm-text { margin-bottom: 4px; font-size: 12px; }
#pm-verdict { font-weight: 700; }
.pm-tl { float: left; }
.pm-tr { float: right; }
.pm-bar { height: 4px; line-height: 0; font-size: 0; }
#pm-full { width: 100%; background-color: #E0E0E0; }
#pm-progress { position: relative; }
#pm-full .ltr { float: left; }
#pm-full .rtl { float: right; }
.pm-pwd_short { color: #676767; }
#pm-full .pm-pwd_short { background-color: #676767; }
.pm-pwd_weak { color: #AA0033; }
#pm-full .pm-pwd_weak { background-color: #AA0033; }
.pm-pwd_moderate { color: #F5AC00; }
#pm-full .pm-pwd_moderate { background-color: #F5AC00; }
.pm-pwd_good { color: #6699CC; }
#pm-full .pm-pwd_good { background-color: #6699CC; }
.pm-pwd_strong { color: #008000; }
#pm-full .pm-pwd_strong { background-color: #008000; }

.section-form #pm-cont { margin: 20px 0 0; }
.section-form #password_container { float: none !important; }

.pm-bottom {
	margin: 1rem 0;
	padding: 0 1rem;
}