.shm-autocomplete-suggestions:not(:empty) {
	background: #fff;
	border-radius: 1.25rem;
	border-top: 2.5rem solid #fff;
	box-shadow: 0 1px #e9ecef inset;
	cursor: default;
	margin-top: -2.5rem;
	max-height: calc(var(--vh, 1vh) * 100 - 4.75rem);
	max-width: calc(100vw - 2rem);
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	z-index: 9999;
}

.shm-autocomplete-suggestions .shm-ac-sg,
.shm-autocomplete-suggestions .shm-ac-title {
	line-height: 1.5;
	padding: .5rem 1rem;
	pointer-events: none;
	position: relative;
}

.shm-autocomplete-suggestions .shm-ac-sg {
	font-size: .875rem;
	font-weight: 500;
}

.shm-autocomplete-suggestions .shm-ac-sg:last-child {
	margin-bottom: .5rem;
}

.shm-autocomplete-suggestions .shm-ac-title {
	align-items: center;
	color: #868e96;
	display: flex;
	font-weight: 400;
	white-space: nowrap;
}

.shm-autocomplete-suggestions .shm-ac-sg[selectable] {
	align-items: center;
	cursor: pointer;
	display: flex;
	padding: .5rem 1rem;
	pointer-events: auto;
}

.shm-autocomplete-suggestions .shm-ac-sg .svg-icon {
	color: #868e96;
	height: 1rem;
	width: 1rem;
}

.shm-autocomplete-suggestions .shm-ac-sg .shm-ac-text {
	margin: 0 .5rem;
}

.shm-autocomplete-suggestions .shm-ac-sg .shm-ac-text-normal,
.shm-autocomplete-suggestions .shm-ac-sg .shm-ac-highlight {
	font-weight: 400;
}

.shm-autocomplete-suggestions .shm-ac-sg .remote-action,
.shm-autocomplete-suggestions .shm-ac-sg .suggestion-select {
	margin-left: auto;
}

@media (max-width: 991.98px) {
	.shm-autocomplete-suggestions .shm-ac-sg,
	.shm-autocomplete-suggestions .shm-ac-title {
		line-height: 1.5;
		min-height: 2.5rem;
	}
	.shm-autocomplete-suggestions .shm-ac-sg[selectable],
	.shm-autocomplete-suggestions .shm-ac-title {
		border-top: 1px solid #e9ecef;
	}
	.shm-autocomplete-suggestions .shm-ac-title {
		background: #f8f9fa;
	}
}

@media (min-width: 992px) {
	.shm-ac-sg.shm-ac-title::after {
		align-self: center;
		background: #dee2e6;
		content: "";
		height: 1px;
		margin-left: .75rem;
		width: 100%;
	}
	.shm-autocomplete-suggestions .shm-ac-title {
		font-size: .75rem;
		margin-top: .5rem;
		text-transform: uppercase;
	}
	.shm-autocomplete-suggestions .shm-ac-sg.selected {
		background: #f1f3f5;
	}
	.shm-autocomplete-suggestions .shm-ac-sg.selected .svg-icon {
		color: #868e96;
	}
}